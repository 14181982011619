"use client";

import {Modal} from "flowbite-react";
import TextInput from "../input/TextInput";
import {useContext, useEffect, useState} from "react";
import {useAuth} from "../../providers/authProvider";
import {ApiContext} from "../contexts/ApiContext";
import {ValidationContext} from "../contexts/ValidationContext";
import DefaultButton from "../buttons/DefaultButton";
import SelectInput from "../input/SelectInput";
import CheckBoxInput from "../input/CheckBoxInput";

function EditCompanyVisitModal(
    props: {
        openModal: any,
        setOpenModal: any,
        visitInfo: any,
    }
) {
    const {Url} = useContext(ApiContext)
    const {token} = useAuth();
    const [VisitDate, setVisitDate] = useState('')
    const [StartTime, setStartTime] = useState('')
    const [EndTime, setEndTime] = useState('')
    const [SoftSignUpDeadline, setSoftSignUpDeadline] = useState('')
    const [HardSignUpDeadline, setHardSignUpDeadline] = useState('')
    const [Locked, setLocked] = useState(false)
    const [Companies, setCompanies] = useState([])
    const [CompanyID, setCompanyID] = useState(0)
    const [PresentCategories, setPresentCategories] = useState([])
    const [PresentCategoryID, setPresentCategoryID] = useState(0)
    const [SignUpCode, setSignUpCode] = useState('')
    const [Address, setAddress] = useState('')
    const [Zipcode, setZipcode] = useState('')
    const [City, setCity] = useState('')
    const [message, setMessage] = useState('')
    const {nameRegex, nameErrorMessage} = useContext(ValidationContext)

    useEffect(() =>{
        //set all information in de modal
        setVisitDate(props.visitInfo.DateISO)
        setStartTime(props.visitInfo.StartTime)
        setEndTime(props.visitInfo.EndTime)
        setSoftSignUpDeadline(props.visitInfo.SoftSignUpDeadlineISO)
        setHardSignUpDeadline(props.visitInfo.HardSignUpDeadlineISO)
        setLocked(props.visitInfo.Locked)
        setCompanyID(props.visitInfo.CompanyID)
        setPresentCategoryID(props.visitInfo.PresentCategoryID)
        setSignUpCode(props.visitInfo.SignUpCode)
        setAddress(props.visitInfo.Address)
        setZipcode(props.visitInfo.Zipcode)
        setCity(props.visitInfo.City)

        }, [props.visitInfo]
    )

    function addCompanyVisit(event) {
        event.preventDefault()
        fetch(Url + 'admin/company-visits', {
            method: 'PATCH',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },

            body: JSON.stringify({
                ID: props.visitInfo.ID,
                Date: VisitDate,
                StartTime: StartTime,
                EndTime: EndTime,
                SoftSignUpDeadline: SoftSignUpDeadline,
                HardSignUpDeadline: HardSignUpDeadline,
                Locked: Locked,
                Address: Address,
                City: City,
                Zipcode: Zipcode,
                CompanyID: Number(CompanyID),
                PresentCategoryID: Number(PresentCategoryID),
                SignUpCode: SignUpCode,
            }),
        })
            .then((res) => {
                if (res.status === 200) {
                    setMessage('')
                    props.setOpenModal(false)
                }
                return res.json()
            })
    }
    useEffect(() => {
        if (token) {
            fetch(Url + 'admin/companies', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': "Bearer " + token,
                },
            })
                .then((res) => res.json())
                .then((data) => setCompanies(data))
        }
    }, [token, Url])

    useEffect(() => {
        if (token) {
            fetch(Url + 'admin/present-categories', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': "Bearer " + token,
                },
            })
                .then((res) => res.json())
                .then((data) => setPresentCategories(data))
        }
    }, [token, Url])

    function handleClose() {
        setMessage('')
        props.setOpenModal(false)
    }

    return (
        <Modal className="mb-10" show={props.openModal} position="center" onClose={handleClose}>
            <Modal.Header><p className="m-4">Bewerk bedrijfsbezoek</p></Modal.Header>
            <Modal.Body>
                <div className="space-y-6 m-4">
                    <p className="text-base leading-relaxed text-gray-500">
                        Geeft de gegevens van het bedrijfsbezoek op en klik op wijzigen.
                    </p>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <SelectInput labelName="Bedrijf"
                                 inputName="companyID"
                                 onChange={(e) => setCompanyID(e.target.value)}
                                 value={CompanyID}
                                 errorMessage="Selecteer een van de opties uit de lijst"
                                 options={Companies}
                    />
                    <TextInput
                        labelName="Bezoekdatum"
                        inputName="visitDate"
                        value={VisitDate}
                        placeholder="05/12/2024"
                        errorMessage="Vul hier geldig datum in"
                        pattern={undefined}
                        type="date"
                        onChange={(e) => setVisitDate(e.target.value)}
                    />
                    <TextInput
                        labelName="Starttijd"
                        inputName="startTime"
                        onChange={(e) => setStartTime(e.target.value)}
                        value={StartTime}
                        placeholder="13:00"
                        errorMessage="Vul hier een geldig tijdstip in"
                        pattern={undefined}
                        type="time"
                    />
                    <TextInput
                        labelName="Eindtijd"
                        inputName="endStime"
                        onChange={(e) => setEndTime(e.target.value)}
                        value={EndTime}
                        placeholder="15:00"
                        errorMessage="Vul hier een geldig tijdstip in"
                        pattern={undefined}
                        type="time"
                    />
                    <TextInput
                        labelName="Adres"
                        inputName="adres"
                        onChange={(e) => setAddress(e.target.value)}
                        value={Address}
                        placeholder="Straat + Huisnummer"
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text"
                    />
                    <TextInput
                        labelName="Postcode"
                        inputName="postcode"
                        onChange={(e) => setZipcode(e.target.value)}
                        value={Zipcode}
                        placeholder="1234AA"
                        errorMessage="Vul hier de postcode zonder spatie in"
                        pattern="[0-9a-zA-Z ]{6,6}"
                        type="text"
                    />
                    <TextInput
                        labelName="Plaats"
                        inputName="city"
                        onChange={(e) => setCity(e.target.value)}
                        value={City}
                        placeholder="Plaats"
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text"
                    />
                    <p>De inschrijfdeadline is de deadline waarna het niet meer mogelijk om nieuwe kinderen in te schrijven. Ook is het na deze deadline niet meer mogelijk om de cadeauselectie te wijzigen. De andere informatie in het grote boek, zoals hobbies kan na de deadline nog wel gewijzigd worden. </p>
                    <TextInput
                        labelName="Inschrijf deadline (zichtbaar voor klanten)"
                        inputName="softSignUpDeadline"
                        value={SoftSignUpDeadline}
                        placeholder="05/12/2024"
                        errorMessage="Vul hier geldig datum in"
                        pattern={undefined}
                        type="date"
                        onChange={(e) => setSoftSignUpDeadline(e.target.value)}
                    />
                    <TextInput
                        labelName="Inschrijf deadline (afgedwongen in systeem)"
                        inputName="hardSignUpDeadline"
                        value={HardSignUpDeadline}
                        placeholder="05/12/2024"
                        errorMessage="Vul hier geldig datum in"
                        pattern={undefined}
                        type="date"
                        onChange={(e) => setHardSignUpDeadline(e.target.value)}
                    />
                    <p>Selecteer alleen een optie als de cadeauservice actief is. Niks selecteert dan is de cadeauservice inactief.</p>
                    <SelectInput labelName="Cadeauservice"
                                 inputName="presentCatID"
                                 onChange={(e) => setPresentCategoryID(e.target.value)}
                                 value={PresentCategoryID}
                                 errorMessage="Selecteer een van de opties uit de lijst"
                                 options={PresentCategories}
                    />
                    <TextInput
                        labelName="Aanmeldcode"
                        inputName="signUpCode"
                        onChange={(e) => setSignUpCode(e.target.value)}
                        value={SignUpCode}
                        placeholder="jd79393"
                        errorMessage="Vul hier de aanmeldcode in. De aanmeldcode moet minimaal acht tekens bevatten. Gebruik geen speciale tekens."
                        pattern="[0-9a-zA-Z ]{8,}"
                        type="text"
                    />
                    <p>
                        Op het moment dat een bedrijf de status "Locked" heeft kan er niks meer gewijzigd worden.
                    </p>
                    <CheckBoxInput labelName="Locked" inputName="locked" value="yes" checked={Locked} setChecked={setLocked}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="m-4 mb-10 grid grid-cols-1 md:grid-cols-2 justify-center items-center">
                    <div className="flex justify-center items-center col-span-1 m-4">
                            <DefaultButton onClick={addCompanyVisit} content="Wijzigen"/>
                        </div>
                        <div className="flex justify-center items-center col-span-1 m-4">
                            <DefaultButton onClick={handleClose} content="Sluiten"/>
                        </div>
                </div>

            </Modal.Footer>
        </Modal>
    );
}

export default EditCompanyVisitModal
