import background from "./img/bg_stad_winter.jpg";
import AuthProvider from "./providers/authProvider";
import Routes from "./routes";
import ApiContextProvider from "./components/contexts/ApiContext";
import ValidationContextProvider from "./components/contexts/ValidationContext";
function App() {
    return (
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundPosition: `center`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `cover`,
            height: `100vh`
        }} >
            <ApiContextProvider>
                <ValidationContextProvider>
                    <AuthProvider>
                        <Routes />
                    </AuthProvider>
                </ValidationContextProvider>
            </ApiContextProvider>
        </div>

    );
}

export default App;
