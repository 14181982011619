import {Link} from 'react-router-dom';
import DropdownUser from './DropdownUser';
import logo from "../../img/redp_logo.png";
import { MdMenu } from "react-icons/md";


function Header (props: {
    sidebarOpen: string | boolean | undefined;
    setSidebarOpen: (arg0: boolean) => void;
}){
    return (
        <header className="sticky top-0 z-999 flex w-full bg-white drop-shadow-1">
            <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
                <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
                    {/* <!-- Hamburger Toggle BTN --> */}

                    <button
                        aria-controls="sidebar"
                        onClick={(e) => {
                            e.stopPropagation();
                            props.setSidebarOpen(!props.sidebarOpen);
                        }}
                        className="z-99999 lg:hidden"

                    >
                    <span className="relative block h-10 w-10 cursor-pointer">
                        <MdMenu size={"1.8em"}/>
                    </span>
                    </button>
                    {/* <!-- Hamburger Toggle BTN --> */}

                    <Link className={`block flex-shrink-0  ${props.sidebarOpen ? 'hidden' : 'lg:hidden'}`} to="/">
                        <img src={logo} width={100} height={75} alt="Logo"/>
                    </Link>
                </div>

                <div className="hidden sm:block">

                </div>

                <div className="flex items-center gap-3 2xsm:gap-7 w-100">
                    {/* <!-- User Area --> */}
                    <DropdownUser/>
                    {/* <!-- User Area --> */}
                </div>
            </div>
        </header>
    );
}

export default Header;