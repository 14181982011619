
import FormLayout from "../../../layouts/FormLayout";
import TextInput from "../../input/TextInput";

import DefaultButton from "../../buttons/DefaultButton";
import H1Header from "../../headers/h1";
import React, {useContext, useEffect, useState} from "react";
import NewChildModal from "../../modals/NewChildModal";
import {SmallTable} from "../../tables/SmallTable";
import PresentSelectionModal from "../../modals/PresentSelectionModal";
import {ApiContext} from "../../contexts/ApiContext";
import EditChildModal from "../../modals/EditChildModal";
import { MdEdit } from "react-icons/md";
import {useNavigate} from "react-router-dom";
import NumberInput from "../../input/NumberInput";
import {ValidationContext} from "../../contexts/ValidationContext";
import {useAuth} from "../../../providers/authProvider";
import {useSearchParams} from "react-router-dom";


function CompanyWelcomePage() {
    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const {nameRegex, nameErrorMessage} = useContext(ValidationContext)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [step, setStep] = useState(0)
    const [openAddChildModal, setOpenAddChildModal] = useState(false);
    const [openEditChildModal, setOpenEditChildModal] = useState(false);
    const [openPresentSelectionModal, setOpenPresentSelectionModal] = useState(false);
    const [children, setChildren] = useState([])
    const [SignUpCode, setSignUpCode] = useState('')
    const [message, setMessage] = useState('')
    const [signUpData, setSignUpData] = useState('')
    const [childrenSet, setChildrenSet] = useState(false)
    const [savingDone, setSavingDone] = useState(false)
    const [savingFailed, setSavingFailed] = useState(false)
    const [adultAmount, setAdultAmount] = useState('')
    const [adultNames, setAdultNames] = useState('')
    const [rowData, setRowData] = useState([])
    const [rowDataPresent, setRowDataPresent] = useState([])
    const [searchParams] = useSearchParams();
    useEffect(() => {
        setSignUpCode(searchParams.get('signup_code'));

    }, [searchParams])

    const getColumnsChildren = () => [
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Leeftijd",
            accessor: "Age",
        },
        {
            id: "edit-button",
            Cell:  <MdEdit />
        }
    ];
    const getColumnsPresents = () => [
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Cadeau",
            accessor: "PresentName",
        },
    ];
    let navigate = useNavigate();
    const goToLogin = () =>{
        let path = `/login`;
        navigate(path);
    }
    function handleRowData (rowDataInput){
        setRowData(rowDataInput)
        if (rowData){
            setOpenEditChildModal(true)
        }
    }
    function handleRowDataPresent (rowDataInput){
        setRowDataPresent(rowDataInput)
        if (rowDataPresent){
            setOpenPresentSelectionModal(true)
        }
    }

    function saveData(){
        setStep(4)
        setMessage("Bezig met opslaan...")
        fetch(Url + 'welcome/new-visitor-registration', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                FirstName : firstName,
                LastName: lastName,
                SignUpCode : SignUpCode,
                Email: email.toLowerCase(),
                Children: children,
                AdultAmount: Number(adultAmount),
                AdultNames: adultNames
            }),
        }).then((res) => {
            if (res.status === 200) {
                //Saving successfully.
                setSavingDone(true)
                setMessage("Het opslaan is gelukt")
            } else {
                //Saving failed
                setSavingFailed(true)
                setMessage("Het opslaan is mislukt, neem contact op.")
            }
        })
    }
    function validatedStep0() {
        //Validate the company code, name and mail.
        if (email === '' || SignUpCode ===''){
            setMessage("Niet alle velden zijn ingevuld. Vul alle velden in en probeer het opnieuw.")
        }else{
            fetch(Url + 'welcome/check-sign-up', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    SignUpCode : SignUpCode,
                    Email: email.toLowerCase()
                }),
            }).then((res) => {
                if (res.status === 200) {
                    setStep(1)
                    return res.json()
                } else {
                    return res.json()
                }
            })
                .then((data) => {
                    if (data.message === "Validation failed for field: Email"){
                        setMessage("Geef een geldig email adres op.")
                    }else if (data.message === "User exist please, sign in."){
                        setMessage("Dit email adres is al in gebruik, gebruik de login knop hieronder om in te loggen.")
                    }else if (data.message === "sign-up code incorrect"){
                        setMessage("De aanmeldcode is onjuist.")
                    }else if (data.message === "locked"){
                        setMessage("Aanmelden voor het feest is niet mogelijk, neem contact met organisatie van je eigen bedrijf.")
                    }
                    else{
                        setMessage('')
                    }
                    setSignUpData(data)
                })
        }
    }
    function validatedStep1(){
        if (firstName === '' || lastName === '' || adultNames === '' || adultAmount === '' ){
            setMessage("Niet alle velden zijn ingevuld. Vul alle velden in en probeer het opnieuw. ")
        }else if (adultAmount < 1){
            setMessage("Er moet minimaal 1 volwassen persoon zich aanmelden.")
        }
        else{
            setStep(2)
            setMessage('')
        }

    }
    function validatedStep2(){
        //check child details, if correct and no present service, save the details. If there is a present service show present selection.
        if (children.length < 1){
            setMessage("Er zijn nog geen kinderen opgegeven. Voeg minimaal 1 kind toe.")
            return
        }
        if (signUpData.PresentServiceActive){
            setStep(3)
            //Present Service is active, show step 3
        }else{
            //Try to save the data
            saveData()
        }
    }
    function validatedStep3(){
        //check if all children have a present selected.
        let validationFailed

        children.forEach(child =>{
            if (!(child.PresentID)){
                validationFailed = true
            }
        })

        if (validationFailed){
            setMessage("Nog niet alle kinderen hebben een cadeau. Selecteer voor alle kinderen een cadeau.")
        }else {
            setMessage('')
            //Try to save the data
            saveData()
        }
    }

    useEffect(() => {
        if (children.length < 1){
            setChildrenSet(false)
        }else {
            setChildrenSet(true)
        }
    }, [children, openAddChildModal, openEditChildModal]);

    useEffect(() =>{
        if (token){
            //User is logged in.
            //Get all variables.

        }
    })
    if (step === 0 ) {
        return (
            <FormLayout>
                <div>
                    <H1Header headerText="Welkom"></H1Header>
                    <div className="my-4">Gezellig! Je komt naar het feest van Piet en Sint. Om het feest tot een
                        geweldig succes te maken hebben wij je hulp nodig. Aangezien de Pieten niet aan elke schoorsteen
                        kunnen luisteren,
                        willen wij graag een aantal dingen over de kind(-eren) weten. Op basis van deze gegevens en de
                        situatie ter plaatse, geven de entertainers invulling aan de show. Zo heeft elke show een
                        persoonlijke toets.
                    </div>
                    <H1Header headerText="Nieuwe aanmelding?"></H1Header>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <div className="my-4">
                        Gebruik hier de aanmeldcode die je van jouw contactpersoon hebt ontvangen. Verder is het goed om
                        te weten dat wij een aantal gegevens zullen delen met jouw contactpersoon (jouw naam en email
                        adres, totaal aantal aanwezigen + namen). Denk hier dus over na bij het invullen van deze
                        gegevens.
                    </div>
                    <TextInput
                        labelName="Aanmeldcode"
                        inputName="signUpCode"
                        placeholder="Aanmeldcode"
                        onChange={(e) => setSignUpCode(e.target.value)}
                        value={SignUpCode}
                        errorMessage="Vul hier de aanmeldcode die je van je contactpersoon hebt gekregen."
                        pattern="[0-9a-zA-Z ]{8,}"
                        type="text">
                    </TextInput>
                    <TextInput
                        labelName="Emailadres"
                        inputName="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        pattern={undefined} //validation is done in by the browser as the type=email
                        type="email"
                        errorMessage={"Vul een geldig mailadres in"}>
                    </TextInput>

                    <div className="flex justify-end">
                        <DefaultButton content="Volgende" onClick={validatedStep0}></DefaultButton>
                    </div>
                    <H1Header headerText="Informatie wijzigen?"></H1Header>
                    <div className="my-4">
                        Als je het formulier al een keer hebt ingevuld kan, je hieronder op inloggen klikken. Na het inloggen
                        kan je de gegevens die je al had ingevuld inzien en/of wijzigen.
                    </div>
                    <div className="flex justify-end">
                        <DefaultButton content="Inloggen" onClick={goToLogin}></DefaultButton>
                    </div>

                </div>
            </FormLayout>
        )
    }
    if (step === 1 ) {
        return (
            <FormLayout>
                <div>
                    <H1Header headerText="Algemene gegevens"></H1Header>
                    <div className="my-4">Gezellig! Je wilt je aanmelden voor het feest van Piet en Sint bij
                        {signUpData.CompanyName} op {signUpData.VisitDate}. Je kan je aanmelden tot en met {signUpData.SoftSignUpDeadline}.
                        Na deze datum is het alleen nog mogelijk om tekstvelden te wijzigen tot een week voor het feest, overige informatie is niet meer aan te passen.
                        Vul hier een aantal algemene gegevens in en klik dan op volgende.
                    </div>
                    <div className="my-4">
                    </div>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <TextInput
                        labelName="Jouw voornaam"
                        inputName="firstName"
                        placeholder="Voornaam"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text">
                    </TextInput>
                    <TextInput
                        labelName="Jouw achternaam"
                        inputName="lastName"
                        placeholder="Achternaam"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text">
                    </TextInput>
                    <NumberInput
                        labelName="Aantal volwassen personen die naar het feest komen."
                        inputName="alduts"
                        onChange={(e) => setAdultAmount(e.target.value)}
                        value={adultAmount}
                        placeholder="Aantal volwassen personen"
                        errorMessage="Het aantal toegestane volwassenen moet tussen de 1 en 10 liggen. Gebruik alleen getallen."
                        type="number"
                        max={9}
                        min={1}
                    />
                    <TextInput
                        labelName="De namen van de volwassen personen die naar het feest komen."
                        inputName="aldutNames"
                        placeholder="Naam1, Naam2, Naam3"
                        onChange={(e) => setAdultNames(e.target.value)}
                        value={adultNames}
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text">
                    </TextInput>
                    <div className="flex justify-end">
                        <DefaultButton content="Volgende" onClick={validatedStep1}></DefaultButton>
                    </div>

                </div>
            </FormLayout>
        )
    }
    if (step === 2) {
        return (
            <FormLayout>
                <div>
                    <H1Header headerText="Overzicht kinderen"></H1Header>
                    <div className="my-4">Voeg hier alle kinderen toe. Klaar met toevoegen? Klik dan
                        op {signUpData.PresentServiceActive ? 'volgende' : 'opslaan'}.
                    </div>
                    <div className="my-4">We gebruiken de informatie die in het Grote Boek staat bij de voorbereiding van ons bezoek. Vaak zijn er
                        heel veel dingen te zeggen over kinderen en zijn er heel veel kinderen. Daarom kan het zijn dat
                        we niet
                        alle informatie die je ons geeft gaan gebruiken.
                    </div>
                    <div className="my-4">Alle informatie die je invult, wordt gedeeld met onze entertainers. De algemene informatie wordt ook gedeeld met onze contactpersoon voor jullie feest die de aanmeldingen graag wil monitoren. Na ons bezoek worden de gegevens uit onze database verwijderd.
                    </div>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <div className="flex justify-center">
                        <DefaultButton content="Voeg een kind toe" onClick={() => setOpenAddChildModal(true)}
                                       disabled={false}></DefaultButton>
                        <NewChildModal openModal={openAddChildModal} setOpenModal={() => setOpenAddChildModal()}
                                       children={children} setChildren={() => setChildren} welcomePage={true}/>
                        <EditChildModal openModal={openEditChildModal} setOpenModal={() => setOpenEditChildModal()}
                                        children={children} setChildren={setChildren} rowData={rowData} welcomePage={true}/>

                    </div>
                    <div className="flex justify-center">
                        {childrenSet &&
                            <SmallTable tableData={children} onRowClick={handleRowData}
                                        getColumns={getColumnsChildren()} />}
                    </div>
                    <div className="flex justify-center">
                        <div className="m-4">
                            <DefaultButton content="Vorige" onClick={() => setStep(1)}></DefaultButton>
                        </div>
                        <div className="m-4">
                            <DefaultButton content={signUpData.PresentServiceActive ? 'Volgende' : 'Opslaan'} onClick={validatedStep2}></DefaultButton>
                        </div>

                    </div>
                </div>
            </FormLayout>
        )
    }
    if (step === 3) {
        return (
            <FormLayout>
                <div>
                    <div className="flex justify-center md:min-w-[700px]">
                        <PresentSelectionModal openModal={openPresentSelectionModal}
                                               setOpenModal={() => setOpenPresentSelectionModal()}
                                               rowdata={rowDataPresent} presents={signUpData.Presents}
                                               children={children} setChildren={setChildren} rowData={rowDataPresent} welcomePage={true}/>
                    </div>
                    <H1Header headerText="Overzicht cadeaus"></H1Header>
                    <div className="my-4">Alle kinderen krijgen een cadeau. Klik op de naam om een cadeau uit te kiezen.
                    </div>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <div className="flex justify-center">
                        <SmallTable tableData={children}
                                    onRowClick={handleRowDataPresent} getColumns={getColumnsPresents()}/>
                    </div>
                    <div className="flex justify-center">
                        <DefaultButton content="Vorige" onClick={() => setStep(2)}></DefaultButton>
                        <DefaultButton content="Opslaan" onClick={validatedStep3}></DefaultButton>
                    </div>
                </div>
            </FormLayout>
        )
    }
    if (step === 4) {
        return (
            <FormLayout>
                <div>
                    <H1Header headerText={message}></H1Header>
                    {savingDone &&
                        <div className="my-4">Bedankt voor het invullen van het Grote Boek! Je bent helemaal klaar. Wij
                            wensen je alvast een fantastisch feest!
                        </div>}
                    {savingDone && <div className="my-4">
                        Wil je alvast van onze muziek genieten? Dat kan via onze
                        <a className="text-blue-700 hover:text-blue-950" target="_blank" rel="noreferrer"
                           href="https://open.spotify.com/artist/6mQqL5ouDAa4dyeZaKIzC8?si=nizcMLEYRSal2Qlw0UWtMw"> Spotify. </a>
                        Tot ziens!
                    </div>}
                    {savingFailed && <div className="my-4">Het opslaan van je gegevens is niet gelukt, neem contact met ons op.
                    </div>}
                </div>
            </FormLayout>
        )
    }
}

export default CompanyWelcomePage