import DefaultLayout from "../../../layouts/DefaultLayout";
import {Table} from "../../tables/BasicTable";
import {useContext, useEffect, useState} from "react";
import {useAuth} from "../../../providers/authProvider";
import BoxLayout from "../../../layouts/BoxLayout";
import DefaultButton from "../../buttons/DefaultButton";
import {ApiContext} from "../../contexts/ApiContext";
import {useNavigate} from "react-router-dom";
import NewHomeVisitCategoryModal from "../../modals/NewHomeVisitCategoryModal";
import H2Header from "../../headers/h2";
import NewHomeVisitModal from "../../modals/NewHomeVisitModal";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";


function AdminHomeVisitsPage() {

    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const [homeVisits, setHomeVisits] = useState([])
    const [homeVisitCategories, setHomeVisitCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataAvailable, setDataAvailable] = useState(false)
    const [dataAvailableCategories, setDataAvailableCategories] = useState(false)
    const [openAddHomeVisitCategoryModal, setOpenAddHomeVisitCategoryModal] = useState(false);
    const [openAddHomeVisitModal, setOpenAddHomeVisitModal] = useState(false);
    const [authorized, setAuthorized] = useState(false)
    const navigate = useNavigate();
    function handleRowData (rowDataInput){
        navigate("/admin/home-visit-details/" + rowDataInput.ID, { replace: false })
    }
    const getColumns = () => [
        {
            Header: "ID",
            accessor: "ID",
        },
        {
            Header: "Naam",
            accessor: "ContactName",
        },
        {
            Header: "Datum",
            accessor: "Date",
        },
        {
            Header: "Soort",
            accessor: "HomeVisitCategoryName",
        },
        {
            Header: "Start tijd",
            accessor: "StartTime",
        },
        {
            Header: "Plaats",
            accessor: "City",
        },
        {
            Header: "Aantal Kinderen",
            accessor: "NumberOfChildren",
        },
        {
            Header: "Team",
            accessor: "Team",
        },
        {
            id: "Planned",
            Header: "Tijdstip Bevestigd",
            accessor: (d: any) => d.Planned ? <FaCheck /> : <ImCross />,
        },
        {
            id: "Locked",
            Header: "Locked",
            accessor: (d: any) => d.Locked ? <FaCheck /> : <ImCross />,
        },
        {
            id: "Payed",
            Header: "Betaalt",
            accessor: (d: any) => d.Payed ? <FaCheck /> : <ImCross />,
        },


    ];
    const handleDownloadPDF = () => {
        fetch(Url + "admin/home-visits-export-pdf", {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download =  "HomeVisitExport.pdf";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };
    const handleDownloadExcel = () => {
        fetch(Url + "admin/home-visits-export-excel", {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download =  "HomeVisitExport.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };
    useEffect(() => {
        if (token) {
            try{
                setLoading(true)
                fetch(Url + 'admin/home-visits', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': "Bearer " + token,
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setDataAvailable(true)
                            setAuthorized(true)
                            return res.json()
                        }else{
                            if (res.status === 401){
                                setAuthorized(false)
                            }else{
                                setAuthorized(true)
                            }
                            setDataAvailable(false)
                            return [{
                                "message": "Geen Huisbezoeken"
                            }]
                        }
                    })
                    .then((data) => {
                        setHomeVisits(data)
                    })
                    .finally(() => {
                        setLoading(false)

                    })
            }catch (error){
                setDataAvailable(false)
                return [{
                    "message": "Laden niet gelukt."
                }]
            }


        }
    }, [token, Url, openAddHomeVisitModal]);
    useEffect(() => {
        if (token) {
            try{
                setLoading(true)
                fetch(Url + 'admin/home-visit-categories', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': "Bearer " + token,
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setDataAvailableCategories(true)
                            setAuthorized(true)
                            return res.json()
                        }else{
                            if (res.status === 401){
                                setAuthorized(false)
                            }else{
                                setAuthorized(true)
                            }
                            setDataAvailable(false)
                            return [{
                                "message": "Geen Huisbezoek categorieen"
                            }]
                        }
                    })
                    .then((data) => {
                        setHomeVisitCategories(data)
                    })
                    .finally(() => {
                        setLoading(false)

                    })
            }catch (error){
                setDataAvailable(false)
                return [{
                    "message": "Laden niet gelukt."
                }]
            }


        }
    }, [token, Url, openAddHomeVisitCategoryModal]);
    let homeCategoryInfo

    if (dataAvailableCategories){
        homeCategoryInfo = (homeVisitCategories.map(homeVisitCategory =>
            <div key={homeVisitCategory.ID}> {homeVisitCategory.Name} - €{homeVisitCategory.SalesPrice.toFixed(2)} </div>
        ))
    }
    else{
        homeCategoryInfo = (<div> Er zijn nog huisbezoek categorieën toegevoegd. </div>)
    }
    let dataTable
    if (dataAvailable) {
        dataTable = (
            <Table tableData={homeVisits} onRowClick={handleRowData} getColumns={getColumns()}></Table>
        )
    }else{
        dataTable = (
            <div>Geen bezoeken in de database of je hebt geen rechten om deze informatie te bekijken.</div>
        )
    }
    return (
        <DefaultLayout>
            <div className="mb-4">
                <NewHomeVisitCategoryModal openModal={openAddHomeVisitCategoryModal} setOpenModal={() => setOpenAddHomeVisitCategoryModal()}/>
                <NewHomeVisitModal openModal={openAddHomeVisitModal} setOpenModal={setOpenAddHomeVisitModal} HomeVisitsCategories={homeVisitCategories} />
            </div>

            <BoxLayout>
            {loading ? (
                <div>Loading...</div>

            ) : (
                <div className="flex flex-col overflow-auto py-4 sm:py-0">
                    {authorized &&  <div className="my-2"><DefaultButton content="Nieuw categorie"  onClick={() => setOpenAddHomeVisitCategoryModal(true)} disabled={false}></DefaultButton> </div>}
                    {authorized && <div className="my-2">  <DefaultButton content="Nieuw bezoek"  onClick={() => setOpenAddHomeVisitModal(true)} disabled={false}></DefaultButton> </div>}
                    {authorized && <div className="my-2">  <DefaultButton content="Export PDF"  onClick={handleDownloadPDF} disabled={false}></DefaultButton> </div>}
                    {authorized && <div className="my-2">  <DefaultButton content="Export Excel"  onClick={handleDownloadExcel} disabled={false}></DefaultButton> </div>}
                    <H2Header headerText="Huisbezoek categorieën"/>
                    {homeCategoryInfo}
                    <H2Header headerText="Huisbezoeken"/>
                    {dataTable}
                </div>
            )}
            </BoxLayout>
        </DefaultLayout>
    )
}
export default AdminHomeVisitsPage