import React, { createContext } from 'react'
export const ValidationContext = createContext()
const ValidationContextProvider = (props) => {
    const nameRegex = "[a-zA-ZÀ-ÿ0-9\\-_,'`\\s]{1,255}"
    const nameErrorMessage = "Ongeldige of te weinig tekens gebruikt."
    return (
        <ValidationContext.Provider
            value={{
                nameRegex,
                nameErrorMessage
            }}>
            {props.children}
        </ValidationContext.Provider>
    )
}
export default ValidationContextProvider