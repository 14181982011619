
function DlWrapper({children}){
    return (
        <div className="border-t border-gray-200">
            <dl>
                {children}
            </dl>
        </div>
    );
}

export default DlWrapper;