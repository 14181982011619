import header from "../img/groteboek-website.jpg";
import React from "react";

function BoxLayout({children}){
    return (
        <section className="h-full flex justify-center items-center font-sans">
            <div className="container h-full p-10">
                <div className="flex h-full flex-wrap items-center justify-center text-neutral-800">
                    <div className="bg-white min-w-96 shadow overflow-hidden rounded-lg">
                        <div>
                            <img
                                className="mx-auto rounded-t-lg"
                                src={header}
                                alt="header" />
                            <div className="px-4 md:px-0 lg:w-full flex justify-center">
                                <div className="m-4 md:mx-6 max-w-[80rem] min-w-[20rem] sm:min-w-[30rem] md:p-12">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BoxLayout;