
function defaultButton (props: {
    content: string | undefined,
    onClick: any ,
}){
    return (
        <button
            className={"flex bg-blue-800 hover:bg-blue-900 text-white cursor-pointer items-center justify-center w-44 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-base font-normal transition-colors rounded-lg"}
            onClick={props.onClick}
        >
            {props.content}
        </button>
    );
}

export default defaultButton;
