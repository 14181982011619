import React, { useRef, useEffect } from 'react';

interface Props {
    children: React.ReactNode;
    exceptionRef?: React.RefObject<HTMLElement>;
    onClick: () => void;
    className?: string;
}

const ClickOutside: React.FC<Props> = ({
                                           children,
                                           exceptionRef,
                                           onClick,
                                           className,
                                       }) => {
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickListener = (event: MouseEvent) => {
            let clickedInside: null | boolean;
            if (exceptionRef) {
                clickedInside =
                    (wrapperRef.current &&
                        wrapperRef.current.contains(event.target)) ||
                (exceptionRef.current && exceptionRef.current === event.target) ||
                (exceptionRef.current &&
                    exceptionRef.current.contains(event.target));
            } else {
                clickedInside =
                    wrapperRef.current &&
                    wrapperRef.current.contains(event.target);
            }

            if (!clickedInside) onClick();
        };

        document.addEventListener('mousedown', handleClickListener);

        return () => {
            document.removeEventListener('mousedown', handleClickListener);
        };
    }, [exceptionRef, onClick, wrapperRef]);

    return (
        <div ref={wrapperRef} className={`${className || ''}`}>
            {children}
        </div>
    );
};

export default ClickOutside;