function BoxHeader (props: {
    headerText: string | undefined;
    subText: string | undefined;
}){
    return (
        <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
                {props.headerText}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {props.subText}
            </p>
        </div>
    );
}

export default BoxHeader;