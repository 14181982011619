import DefaultLayout from "../../../layouts/DefaultLayout";
import {Table} from "../../tables/BasicTable";
import {useContext, useEffect, useState} from "react";
import {useAuth} from "../../../providers/authProvider";
import BoxLayout from "../../../layouts/BoxLayout";
import DefaultButton from "../../buttons/DefaultButton";
import NewUserModal from "../../modals/NewUserModal";
import {ApiContext} from "../../contexts/ApiContext";
import EditUserModal from "../../modals/EditUserModal";


function AdminUsersPage() {
    function handleRowData (rowData){
        setRowData(rowData)
        if (rowData){
            setOpenEditUserModal(true)
        }
    }
    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [openAddUserModal, setOpenAddUserModal] = useState(false);
    const [openEditUserModal, setOpenEditUserModal] = useState(false);
    const [rowData, setRowData] = useState([])
    const [dataAvalible, setDataAvalible] = useState(false)

    const getColumns = () => [
        {
            Header: "ID",
            accessor: "ID",
        },
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Achternaam",
            accessor: "LastName",
        },
        {
            Header: "Email",
            accessor: "Email",
        },
        {
            Header: "Telefoonnummer",
            accessor: "Phone",
        },
        {
            Header: "Admin",
            accessor: "Admin",
        },
    ];

    useEffect(() => {
        if (token) {
            setLoading(true)

                fetch(Url + 'admin/users', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': "Bearer " + token,
                    },
                })
                    .then((res) => {
                        if (res.status !== 200){
                            console.log("Data cannot be fetched")
                            setDataAvalible(false)
                            if (res.status === 401){
                                //Unauthorized
                            }
                        }else{
                            setDataAvalible(true)
                        }
                        return res.json()
                    })
                    .then((data) => {
                        setUsers(data)
                    })
                    .finally(() => {
                        setLoading(false)
                    })


        }

    }, [token, Url, openAddUserModal, openEditUserModal]);
    let table
    if (dataAvalible){
        table = (<Table tableData={users} setRowdata={setRowData} onRowClick={handleRowData} getColumns={getColumns()} />)
    }else{
        table = <BoxLayout><div>User data niet beschikbaar of je hebt geen rechten om deze informatie te bekijken.</div></BoxLayout>
    }
    return (
        <DefaultLayout>
            <div className="mb-4">
                {dataAvalible && <DefaultButton content="Nieuwe gebruiker"  onClick={() => setOpenAddUserModal(true)} disabled={false}></DefaultButton>}
            </div>

            <NewUserModal openModal={openAddUserModal} setOpenModal={() => setOpenAddUserModal()} />
            <EditUserModal openModal={openEditUserModal} setOpenModal={() => setOpenEditUserModal()} rowData={rowData} />
            {loading ? (
                <BoxLayout><div>Loading...</div></BoxLayout>

            ) : (
                <div className="flex flex-col overflow-auto py-4 sm:py-0">
                    {table}
                </div>
            )}
        </DefaultLayout>
    )
}
export default AdminUsersPage