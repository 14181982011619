
function ButtonTable (props: {
    content: string | undefined,
    onClick: any,
    active: boolean
}){
    return (
        <button
            className={`flex flex-col cursor-pointer  items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
                ${props.active ? "bg-blue-700 text-white" : " bg-white text-blue-700"}
      `     }
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.content}
        </button>
    );
}

export default ButtonTable;