import DefaultLayout from "../../../layouts/DefaultLayout";
import {Table} from "../../tables/BasicTable";
import React, {useContext, useEffect, useState} from "react";
import {useAuth} from "../../../providers/authProvider";
import BoxLayout from "../../../layouts/BoxLayout";
import DefaultButton from "../../buttons/DefaultButton";
import {ApiContext} from "../../contexts/ApiContext";

import {useNavigate, useParams} from "react-router-dom";
import H2Header from "../../headers/h2";

import EditHomeVisitModal from "../../modals/EditHomeVisitModal";
import EditChildModal from "../../modals/EditChildModal";
import {MdEdit} from "react-icons/md";
import EditAdultModal from "../../modals/EditAdultModal";


function AdminHomeVisitDetailPage() {
    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const [homeVisitDetails, setHomeVisitDetails] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataAvailable, setDataAvailable] = useState(false)
    const [openEditHomeVisitModal, setOpenEditHomeVisitModal] = useState(false);
    const [openEditChildModal, setOpenEditChildModal] = useState(false);
    const [openEditAdultModal, setOpenEditAdultModal] = useState(false);
    const [rowData, setRowData] = useState([])
    const navigate = useNavigate();

    // Get ID from URL
    const params = useParams();

    const getColumnsChildern = () => [
        {
            Header: "ID",
            accessor: "ID",
        },
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Achternaam",
            accessor: "LastName",
        },
        {
            Header: "Leeftijd",
            accessor: "Age",
        },
        {
            id: "edit-button",
            Cell:  <MdEdit />
        }

    ];

    const getColumnsAdults = () => [
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Achternaam",
            accessor: "LastName",
        },
        {
            id: "edit-button",
            Cell:  <MdEdit />
        }
    ];

    function getHomeVisitDetails(){
        if (token) {
            fetch(Url + 'admin/home-visit-details/' + params.id, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': "Bearer " + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        setDataAvailable(true)
                        return res.json()
                    }else{
                        setDataAvailable(false)
                        return [{
                            "message": "Geen informatie voor dit huisbezoek beschikbaar."
                        }]
                    }
                })
                .then((data) => {
                    setHomeVisitDetails(data)
                })
                .finally(() => {
                    setLoading(false)

                })

        }
    }

    useEffect(() => {
            setLoading(true)
            getHomeVisitDetails()
        },
        [token, Url]);
    useEffect(() => {
            getHomeVisitDetails()
        },
        [openEditHomeVisitModal, openEditChildModal, openEditAdultModal]);
    function handleRowData (rowDataInput){
        setRowData(rowDataInput)
        if (rowData){
            setOpenEditChildModal(true)
        }
    }
    function handleRowDataAdult (rowDataInput){
        setRowData(rowDataInput)
        if (rowData){
            setOpenEditAdultModal(true)
        }
    }

    let dataTable
    if (homeVisitDetails.Children){
        dataTable =  (<Table tableData={homeVisitDetails.Children} getColumns={getColumnsChildern()} onRowClick={handleRowData}></Table>)
    }else {
        dataTable = <div>Er zijn nog geen kinderen ingeschreven voor dit bezoek</div>
    }
    let dataTableAdults
    if (homeVisitDetails.Adults){
        dataTableAdults =  (<Table tableData={homeVisitDetails.Adults} getColumns={getColumnsAdults()} onRowClick={handleRowDataAdult}></Table>)
    }else {
        dataTableAdults = <div>Er zijn nog geen volwassenen ingeschreven voor dit bezoek</div>
    }


    let lockedInfo
    if (homeVisitDetails.Locked){
        lockedInfo = (
            <div className=" my-4 text-xl font-extrabold text-red-600 ">GROTE BOEK VOOR DIT BEZOEK STAAT DICHT! INFORMATIE KAN NIET GEWIJZIGD WORDEN DOOR KLANTEN!</div>
        )
    }else{
        lockedInfo = (<div className="my-4 text-xl font-extrabold text-red-600">LOCK NIET ACTIEF INFORMATIE KAN DOOR KLANTEN GEWIJZIGD WORDEN!</div>)
    }
    let plannedInfo
    if (homeVisitDetails.Planned){
        plannedInfo = (
            <div className=" my-4 text-xl font-extrabold text-red-600 ">TIJDSTIP HEEFT DE STATUS BEVESTIGD. KLANT ZIET HET DEFINITIEVE TIJDSTIP</div>
        )
    }else{
        plannedInfo = (<div className="my-4 text-xl font-extrabold text-red-600">TIJDSTIP IS NOG NIET BEVESTIGD. KLANT ZIET ALLEEN HET TIJDSTIPBLOK</div>)
    }
    let dataViewer
    if (dataAvailable) {
        dataViewer = (
            <BoxLayout>
                <div className="mb-4 mt-4">
                    <DefaultButton content="Terug"
                                   onClick={() => navigate("/admin/home-visits/", {replace: false})}></DefaultButton>
                </div>
                <H2Header headerText={homeVisitDetails.Home}/>
                <H2Header headerText="Algemeen"/>
                {lockedInfo}
                <div className="text-xl">Voornaam: {homeVisitDetails.FirstName}</div>
                <div className="text-xl">Achternaam: {homeVisitDetails.LastName}</div>
                <div className="text-xl">Email: {homeVisitDetails.Email}</div>
                <div className="text-xl">Telefoonnummer: {homeVisitDetails.Phone}</div>
                <div className="text-xl">Soort optreden: {homeVisitDetails.HomeVisitCategoryName}</div>
                <div className="text-xl">Cadeaulocatie: {homeVisitDetails.PresentLocation}</div>
                <div className="text-xl">Strooien: {homeVisitDetails.Sprinkle}</div>
                <div className="text-xl">Team: {homeVisitDetails.Team}</div>
                <div className="text-xl">Bijzonderheden: {homeVisitDetails.Comments}</div>
                <div className="text-xl">Parkeren: {homeVisitDetails.Parking}</div>
                <div className="mb-4 mt-4">
                    <DefaultButton content="Bewerken" onClick={() => setOpenEditHomeVisitModal(true)}></DefaultButton>
                </div>

                <H2Header headerText="Datum en tijd"/>
                {plannedInfo}
                <div className="text-xl">Datum: {homeVisitDetails.Date}</div>
                <div className="text-xl">Starttijd: {homeVisitDetails.StartTime}</div>
                <div className="text-xl">Begin Tijdslot: {homeVisitDetails.StartTimeSlot}</div>
                <div className="text-xl">Eind Tijdslot: {homeVisitDetails.EndTimeSlot}</div>

                <H2Header headerText="Locatie"/>
                <div className="text-xl">Adres: {homeVisitDetails.Address}</div>
                <div className="text-xl">Postcode: {homeVisitDetails.ZipCode}</div>
                <div className="text-xl">Plaats: {homeVisitDetails.City}</div>


                <H2Header headerText="Ingeschreven kinderen"/>
                {dataTable}
                <H2Header headerText="Ingeschreven volwassenen"/>
                {dataTableAdults}

            </BoxLayout>
        )
    } else {
        dataViewer = (
            <BoxLayout>
            <div>Geen data voor dit bezoek in de database</div>
            </BoxLayout>
        )
    }
    return (
        <DefaultLayout>
            <EditHomeVisitModal openModal={openEditHomeVisitModal} setOpenModal={setOpenEditHomeVisitModal}
                                visitInfo={homeVisitDetails}/>
            <EditChildModal openModal={openEditChildModal} setOpenModal={setOpenEditChildModal} welcomePage={false} rowData={rowData} admin={true}/>
            <EditAdultModal openModal={openEditAdultModal} setOpenModal={setOpenEditAdultModal} rowData={rowData} admin={true}/>
            {loading ? (
                <BoxLayout>
                    <div>Loading...</div></BoxLayout>

            ) : (
                <div className="flex flex-col overflow-auto py-4 sm:py-0">
                    {dataViewer}
                </div>
            )}
        </DefaultLayout>
    )
}
export default AdminHomeVisitDetailPage