import {
    FaSortUp,
    FaSortDown,
} from "react-icons/fa";

function TableComponent({
                            headerGroups,
                            rows,
                            prepareRow,
                            onRowClick,
                        },
) {
    return (
        <div className="w-full min-w-[20rem] p-4 bg-white rounded-xl shadow-[0_4px_10px_rgba(0,0,0,0.03)]">
            <table>
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr key={headerGroup.getHeaderGroupProps().key}>
                        {headerGroup.headers.map((column) => (
                            <th
                                key={column.id}
                                {...column.getSortByToggleProps()}
                                className="px-3 text-start text-sm font-light uppercase cursor-pointer"
                                style={{ width: column.width }}
                            >
                                <div className="flex gap-2 items-center">
                                    <div className="text-gray-600">
                                        {column.render("Header")}
                                    </div>
                                    <div className="flex flex-col">
                                        <FaSortUp
                                            className={`text-sm translate-y-1/2 ${
                                                column.isSorted && !column.isSortedDesc
                                                    ? "text-blue-700"
                                                    : "text-gray-300"
                                            }`}
                                        />
                                        <FaSortDown
                                            className={`text-sm -translate-y-1/2 ${
                                                column.isSortedDesc ? "text-blue-700" : "text-gray-300"
                                            }`}
                                        />
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr key={row.id} onClick={() => onRowClick(row.original)} className="hover:bg-gray-100">
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        key={cell.getCellProps().key}
                                        className="p-3 font-normal text-gray-700 first:rounded-l-lg last:rounded-r-lg"
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

export { TableComponent };
