
"use client";

import {Modal } from "flowbite-react";
import TextInput from "../input/TextInput";
import {useContext, useState} from "react";
import {useAuth} from "../../providers/authProvider";
import {ApiContext} from "../contexts/ApiContext";
import DefaultButton from "../buttons/DefaultButton";
import {ValidationContext} from "../contexts/ValidationContext";

function NewCompanyModal(
    props: {
        openModal: any;
        setOpenModal: any
    }
) {
    const {Url } = useContext(ApiContext)
    const [Name, setName] = useState('')
    const [Address, setAddress] = useState('')
    const [City, setCity] = useState('')
    const [Zipcode, setZipcode] = useState('')
    const [message, setMessage] = useState('')
    const { token } = useAuth();
    const {nameRegex, nameErrorMessage} = useContext(ValidationContext)

    function addCompany(event) {
        event.preventDefault()

        fetch(Url + 'admin/companies', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },

            body: JSON.stringify({
                Name: Name,
                Address: Address,
                City: City,
                Zipcode: Zipcode,
            }),
        })
            .then((res) => {
                if (res.status === 200) {
                    setName('')
                    setAddress('')
                    setCity('')
                    setZipcode('')
                }
                return res.json()
            })
            .then((data) => {
                setMessage(data.message)
            })
    }
    function handleClose(){
        setMessage('')
        props.setOpenModal(false)
    }

    return (
        <Modal  show={props.openModal} position="center" onClose={handleClose} >
            <Modal.Header><p className="m-4">Nieuw bedrijf toevoegen</p></Modal.Header>
            <Modal.Body>
                <div className="space-y-6 m-4">
                    <p className="text-base leading-relaxed text-gray-500">
                        Geeft de gegevens van het nieuwe bedrijf op en klik op toevoegen.
                    </p>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <TextInput
                        labelName="Naam"
                        inputName="Name"
                        value={Name}
                        placeholder="Naam"
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextInput
                        labelName="Adres"
                        inputName="Address"
                        onChange={(e) => setAddress(e.target.value)}
                        value={Address}
                        placeholder="Paashaasplein 1"
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text"
                    />
                    <TextInput
                        labelName="Postcode"
                        inputName="postcode"
                        onChange={(e) => setZipcode(e.target.value)}
                        value={Zipcode}
                        placeholder="1234AA"
                        errorMessage="Vul hier de postcode zonder spatie in"
                        pattern="[0-9a-zA-Z ]{6,6}"
                        type="text"
                    />
                    <TextInput
                        labelName="Plaats"
                        inputName="city"
                        onChange={(e) => setCity(e.target.value)}
                        value={City}
                        placeholder="Plaats"
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-10 grid grid-cols-1 gap-0 md:grid-cols-2">
                    <div className="m-4">
                        <DefaultButton onClick={addCompany} content="Toevoegen" />
                    </div>
                    <div className="m-4">
                        <DefaultButton onClick={handleClose} content="Sluiten" />
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
    );
}
export default NewCompanyModal
