
"use client";

import {Modal } from "flowbite-react";
import ButtonGreen from "../buttons/ButtonGreen";
import TextInput from "../input/TextInput";
import PasswordInput from "../input/PasswordInput";
import {useContext, useEffect, useState} from "react";
import {useAuth} from "../../providers/authProvider";
import {ApiContext} from "../contexts/ApiContext";
import NumberInput from "../input/NumberInput";
import DefaultButton from "../buttons/DefaultButton";
import SelectInput from "../input/SelectInput";
import TextArea from "../input/TextArea";

function NewPresentModal(
    props: {
        openModal: any;
        setOpenModal: any,
        rowData: any
    }
) {
    const {Url } = useContext(ApiContext)
    const [message, setMessage] = useState('')
    const { token } = useAuth();
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [sortingIndex, setSortingIndex] = useState(1)
    const [presentCategoryID, setPresentCategoryID] = useState(1)
    const [presentCategories, setPresentCategories] = useState([])
    const [imageURL, setImageURL] = useState('')
    function handleClose(){
        setMessage('')
        props.setOpenModal(false)
    }
    useEffect(() => {
        setName(props.rowData.Name)
        setDescription(props.rowData.Description)
        setSortingIndex(props.rowData.SortingIndex)
        setPresentCategoryID(props.rowData.PresentCategoryID)
        setImageURL(props.rowData.ImageURL)
    }, [props.rowData]);
    function editPresent(event) {
        event.preventDefault()
        fetch(Url + 'admin/presents', {
            method: 'PATCH',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                ID: props.rowData.ID,
                Name: name,
                Description: description,
                SortingIndex: Number(sortingIndex),
                PresentCategoryID: Number(presentCategoryID),
                ImageURL: imageURL }),
        })
            .then((res) => {
                if (res.status === 200) {
                    setName('')
                    setDescription('')
                    setSortingIndex('')
                    setImageURL('')
                    handleClose()
                }else{
                    setMessage("Bijwerken van cadeau mislukt.")
                }
                return res.json()
            })
    }
    useEffect(() => {
        if (token) {
            fetch(Url + 'admin/present-categories', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': "Bearer " + token,
                },
            })
                .then((res) => res.json())
                .then((data) => setPresentCategories(data))
        }
    }, [token, Url])
    return (
        <Modal show={props.openModal} position="center" onClose={() => props.setOpenModal(false)} >
            <Modal.Header><p className="m-4">Bewerk cadeau </p></Modal.Header>
            <Modal.Body>
                <div className="space-y-6 m-4">
                    <p className="text-base leading-relaxed text-gray-500">
                        Geef de gegevens van het cadeau op en klik op bijwerken.
                    </p>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <TextInput
                        labelName="Naam"
                        inputName="name"
                        onChange={(e) => setName(e.target.value)}
                        errorMessage={"Vul hier de naam het cadeau in. Gebruik minimaal 2 letters."}
                        pattern={"[0-9a-zA-Z ]{2,}"}
                        placeholder={"Naam"}
                        type={"text"}
                        value={name}
                    />
                    <TextArea
                        labelName="Omschrijving"
                        inputName="description"
                        onChange={(e) => setDescription(e.target.value)}
                        errorMessage={"Vul hier de omschrijving van het cadeau in. Gebruik minimaal 2 letters."}
                        placeholder={"Omschrijving"}
                        value={description}
                        rows={2}
                    />
                    <SelectInput labelName="Cadeauservice"
                                 inputName="presentCatID"
                                 onChange={(e) => setPresentCategoryID(e.target.value)}
                                 value={presentCategoryID}
                                 errorMessage="Selecteer een van de opties uit de lijst"
                                 options={presentCategories}
                    />
                    <NumberInput
                        labelName="Index - Deze index wordt gebruikt voor het sorteren van de cadeauselectie op de kind toevoegen pagina. 1 is bovenaan."
                        inputName="sortingIndex"
                        onChange={(e) => setSortingIndex(e.target.value)}
                        errorMessage={"Vul een geldig positief getal in."}
                        max={undefined}
                        min={0}
                        placeholder={1}
                        type={"number"}
                        value={sortingIndex}
                    />
                    <TextInput
                        labelName="URL van het plaatje"
                        inputName="imageURL"
                        onChange={(e) => setImageURL(e.target.value)}
                        errorMessage={"Geef een geldige URL op"}
                        placeholder={"https://ridderendeprins.nl/img.jpg"}
                        type={"url"}
                        value={imageURL}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-10 grid grid-cols-1 gap-0 md:grid-cols-2">
                    <div className="flex justify-center items-center col-span-1 m-4">
                        <DefaultButton onClick={editPresent} content="Wijzigen"/>
                    </div>
                    <div className="flex justify-center items-center col-span-1 m-4">
                        <DefaultButton onClick={handleClose} content="Sluiten"/>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
);
}
export default NewPresentModal
