import DefaultLayout from "../../../layouts/DefaultLayout";
import {useContext, useEffect, useState} from "react";
import {useAuth} from "../../../providers/authProvider";
import BoxLayout from "../../../layouts/BoxLayout";
import DefaultButton from "../../buttons/DefaultButton";
import {ApiContext} from "../../contexts/ApiContext";
import H1Header from "../../headers/h1";
import NewPresentCategoryModal from "../../modals/NewPresentCategoryModal";
import {SmallTableWithHeader} from "../../tables/SmallTableWithHeader";
import NewPresentModal from "../../modals/NewPresentModal";
import EditPresentModal from "../../modals/EditPresentModal";


function AdminPresentsPage() {

    const {Url } = useContext(ApiContext)
    const [presentCategories, setPresentCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const [openPresentCategoryModal, setOpenPresentCategoryModal] = useState(false);
    const [openPresentModal, setOpenPresentModal] = useState(false);
    const [openEditPresentModal, setOpenEditPresentModal] = useState(false);
    const [dataAvalible, setdataAvalible] = useState(false)
    const [rowDataPresent, setRowDataPresent] = useState([])
    const [authorized, setAuthorized] = useState(false)
    const { token } = useAuth();

    function handleRowDataPresent (rowData){
        setRowDataPresent(rowData)
        if (rowData){
            setOpenEditPresentModal(true)
        }
    }
    useEffect(() => {
        if (token) {
            setLoading(true)
            fetch(Url + 'admin/present-categories', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': "Bearer " + token,
                },
            })
                .then((res) =>   {
                    if (res.status !== 200){
                        console.log("Data cannot be fetched")
                        setdataAvalible(false)
                        if (res.status === 401){
                            setAuthorized(false)
                        }else{
                            setAuthorized(true)
                        }
                    }else{
                        setAuthorized(true)
                        setdataAvalible(true)
                    } return res.json()
                })
                .then((data) => setPresentCategories(data))
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [token, Url, openPresentCategoryModal, openPresentModal, openEditPresentModal]);


    const getColumnsPresents = () => [
        {
            Header: "ID",
            accessor: "ID",
        },
        {
            Header: "Name",
            accessor: "Name",
        },
        {
            Header: "Index",
            accessor: "SortingIndex",
        },
    ];
    let table
    if (dataAvalible){
        table = (presentCategories.map(presentCategory =>
                <SmallTableWithHeader key={presentCategory.ID} id={presentCategory.ID} headerText={presentCategory.ID + ". " + presentCategory.Name + " - " + presentCategory.SalesPrice + " euro"} tableData={presentCategory.Presents} getColumns={getColumnsPresents()} onRowClick={handleRowDataPresent}/>
            ))
    }else{
        table = <div>Data niet beschikbaar of je hebt geen rechten om deze informatie te bekijken.</div>
    }
    return (
        <DefaultLayout>
            <NewPresentCategoryModal openModal={openPresentCategoryModal} setOpenModal={() => setOpenPresentCategoryModal()}  />
            <NewPresentModal openModal={openPresentModal} setOpenModal={() => setOpenPresentModal()}  />
            <EditPresentModal openModal={openEditPresentModal} setOpenModal={() =>setOpenEditPresentModal()} rowData={rowDataPresent}/>

            {loading ? (
                <BoxLayout><div>Loading...</div></BoxLayout>

            ) : (
                <BoxLayout>

                    {authorized && <H1Header headerText="Cadeau overzicht" />}
                    <div className="mt-4 mb-4">
                        {authorized &&  <DefaultButton content="Nieuwe categorie"  onClick={() => setOpenPresentCategoryModal(true)} disabled={false}></DefaultButton> }
                    </div>
                    <div className="mt-4 mb-4">
                        {authorized &&  <DefaultButton content="Nieuw cadeau"  onClick={() => setOpenPresentModal(true)} disabled={false}></DefaultButton>}
                    </div>

                    <div className="flex flex-col overflow-auto py-4 sm:py-0">
                        {table}
                    </div>
                </BoxLayout>
            )}
        </DefaultLayout>
    )
}
export default AdminPresentsPage