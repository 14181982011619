import BoxLayout from "../../layouts/BoxLayout";
import FormLayout from "../../layouts/FormLayout";

function PageNotFound() {

    return (
        <FormLayout>
            <section className="h-full flex justify-center items-center">
                    Deze pagina bestaat niet.
            </section>
        </FormLayout>
    );
}

export default PageNotFound;
