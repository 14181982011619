import DefaultLayout from "../../../layouts/DefaultLayout";
import {Table} from "../../tables/BasicTable";
import {useContext, useEffect, useState} from "react";
import {useAuth} from "../../../providers/authProvider";
import BoxLayout from "../../../layouts/BoxLayout";
import DefaultButton from "../../buttons/DefaultButton";
import {ApiContext} from "../../contexts/ApiContext";
import NewCompanyModal from "../../modals/NewCompanyModal";
import EditCompanyModal from "../../modals/EditCompanyModal";


function AdminCompaniesPage() {
    function handleRowData (rowData){
        setRowData(rowData)
        if (rowData){
            setOpenEditCompanyModal(true)
        }
    }
    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const [companies, setCompanies] = useState([])
    const [loading, setLoading] = useState(false)
    const [openAddCompanyModal, setOpenAddCompanyModal] = useState(false);
    const [openEditCompanyModal, setOpenEditCompanyModal] = useState(false);
    const [dataAvalible, setDataAvalible] = useState(false)
    const [rowData, setRowData] = useState([])
    const [authorized, setAuthorized] = useState(false)

    const getColumns = () => [
        {
            Header: "ID",
            accessor: "ID",
        },
        {
            Header: "Naam",
            accessor: "Name",
        },
        {
            Header: "Adres",
            accessor: "Address",
        },
        {
            Header: "Postcode",
            accessor: "Zipcode",
        },
        {
            Header: "Plaats",
            accessor: "City",
        },
    ];
    useEffect(() => {
        if (token) {
            setLoading(true)
            fetch(Url + 'admin/companies', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': "Bearer " + token,
                },
            })
                .then((res) => {
                    if (res.status !== 200){
                        console.log("Data cannot be fetched")
                        setDataAvalible(false)
                        if (res.status === 401){
                            setAuthorized(false)
                        }else{
                            setAuthorized(true)
                        }
                    }else{
                        setAuthorized(true)
                        setDataAvalible(true)
                    }
                    return res.json()
                })
                .then((data) => setCompanies(data))
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [token, Url, openAddCompanyModal, openEditCompanyModal]);
    let table
    if (dataAvalible){
        table = ( <Table tableData={companies} setRowdata={setRowData} onRowClick={handleRowData} getColumns={getColumns()} />)
    }else{
        table = <BoxLayout><div>Data niet beschikbaar of je hebt geen rechten om deze informatie te bekijken.</div></BoxLayout>
    }

    return (
        <DefaultLayout>
            <div className="mb-4">
                {authorized && <DefaultButton content="Nieuw bedrijf"  onClick={() => setOpenAddCompanyModal(true)} disabled={false}></DefaultButton> }

            </div>

            <NewCompanyModal openModal={openAddCompanyModal} setOpenModal={setOpenAddCompanyModal} />
            <EditCompanyModal openModal={openEditCompanyModal} setOpenModal={setOpenEditCompanyModal} rowData={rowData} />

            {loading ? (
                <BoxLayout><div>Loading...</div></BoxLayout>

            ) : (
                <div className="flex flex-col overflow-auto py-4 sm:py-0">
                    {table}
                </div>
            )}
        </DefaultLayout>
    )
}
export default AdminCompaniesPage