import H2Header from "../headers/h2";
import {SmallTable} from "./SmallTable";

function SmallTableWithHeader(
    props: {
        tableData: any,
        getColumns: any,
        onRowClick: any,
        headerText: string,
    }
) {

    return (
        <div className="flex flex-col gap-4">
            <H2Header headerText={props.headerText}/>
            <SmallTable getColumns={props.getColumns} onRowClick={props.onRowClick} tableData={props.tableData}/>
        </div>
    );
}

export { SmallTableWithHeader };
