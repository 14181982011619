
"use client";

import {Modal } from "flowbite-react";
import TextInput from "../input/TextInput";
import PasswordInput from "../input/PasswordInput";
import {useContext, useState} from "react";
import {useAuth} from "../../providers/authProvider";
import {ApiContext} from "../contexts/ApiContext";
import {ValidationContext} from "../contexts/ValidationContext";
import DefaultButton from "../buttons/DefaultButton";
import CheckBoxInput from "../input/CheckBoxInput";

function NewUserModal(
    props: {
        openModal: any;
        setOpenModal: any
    }
) {
    const { token } = useAuth();
    const {Url } = useContext(ApiContext)
    const [FirstName, setFirstName] = useState('')
    const [LastName, setLastName] = useState('')
    const [Email, setEmail] = useState('')
    const [Phone, setPhone] = useState('')
    const [Password, setPassword] = useState('')
    const [message, setMessage] = useState('')
    const [adminRole, setAdminRol] = useState(false)
    const {nameRegex, nameErrorMessage} = useContext(ValidationContext)

    function addUser(event) {
        event.preventDefault()

        //Api excepts an array, create one. Role with ID 1 is always admin
        let roleArray
        if (adminRole){
            roleArray = [1]
        }else{
            roleArray = []
        }

        fetch(Url + 'admin/users', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },

            body: JSON.stringify({
                FirstName: FirstName,
                LastName: LastName,
                Email: Email.toLowerCase(),
                Phone: Phone,
                Password: Password,
                Roles: roleArray
            }),
        })
            .then((res) => {
                if (res.status === 200) {
                    setFirstName('')
                    setLastName('')
                    setPassword('')
                    setEmail('')
                    setPhone('')
                    setPassword('')
                    setAdminRol(false)
                }
                return res.json()
            })
            .then((data) => {
                setMessage(data.message)
            })
    }
    function handleClose(){
        setMessage('')
        props.setOpenModal(false)
    }

    return (
        <Modal show={props.openModal} position="center" onClose={handleClose} >
            <Modal.Header><p className="m-4">Nieuwe gebruiker toevoegen</p></Modal.Header>
            <Modal.Body>
                <div className="space-y-6 m-4">
                    <p className="text-base leading-relaxed text-gray-500">
                        Geeft de gegevens van een nieuwe gebruiker op en klik op toevoegen.
                    </p>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <TextInput
                        labelName="Voornaam"
                        inputName="FirstName"
                        value={FirstName}
                        placeholder="Voornaam"
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text"
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextInput
                        labelName="Achternaam"
                        inputName="lastName"
                        onChange={(e) => setLastName(e.target.value)}
                        value={LastName}
                        placeholder="Achternaam"
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text"
                    />
                    <TextInput
                        labelName="Email"
                        inputName="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={Email}
                        placeholder="Email"
                        errorMessage="Vul hier je email in"
                        pattern={undefined}
                        type="email"
                    />
                    <TextInput
                        labelName="Telefoonnummer"
                        inputName="phone"
                        onChange={(e) => setPhone(e.target.value)}
                        value={Phone}
                        placeholder="Telefoonnummer"
                        errorMessage="Vul hier je Telefoonummer in, gebruik alleen getallen, geen + of -"
                        pattern="[0-9]{10,}"
                        type="text"
                    />
                    <PasswordInput labelName="Wachtwoord" value={Password} inputName="password" onChange={(e) => setPassword(e.target.value)} errorMessage="Ongeldig wachtwoord, je wachtwoord moet minimaal 10 tekens lang zijn" pattern=".{10,}" />
                    <CheckBoxInput labelName="Admin" inputName="admin" value="yes" checked={adminRole} setChecked={setAdminRol}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="grid grid-cols-1 gap-0 md:grid-cols-2">
                    <div className="m-4">
                        <DefaultButton onClick={addUser} content="Toevoegen" />
                    </div>
                    <div className="m-4">
                        <DefaultButton onClick={handleClose} content="Sluiten" />
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
    );
}
export default NewUserModal
