import FormLayout from "../../../layouts/FormLayout";
import TextInput from "../../input/TextInput";

import DefaultButton from "../../buttons/DefaultButton";
import H1Header from "../../headers/h1";
import React, {useContext, useEffect, useState} from "react";
import NewChildModal from "../../modals/NewChildModal";
import {SmallTable} from "../../tables/SmallTable";
import {ApiContext} from "../../contexts/ApiContext";
import EditChildModal from "../../modals/EditChildModal";
import {MdEdit} from "react-icons/md";
import {useNavigate, useParams} from "react-router-dom";
import NumberInput from "../../input/NumberInput";
import {ValidationContext} from "../../contexts/ValidationContext";
import {useAuth} from "../../../providers/authProvider";
import H2Header from "../../headers/h2";
import SelectInput from "../../input/SelectInput";
import TextArea from "../../input/TextArea";
import NewAdultModal from "../../modals/NewAdultModal";
import EditAdultModal from "../../modals/EditAdultModal";


function HomeVisitDetailsPage() {
    const {Url} = useContext(ApiContext)
    const {token} = useAuth();
    const {nameRegex, nameErrorMessage} = useContext(ValidationContext)
    const [step, setStep] = useState(0)
    const [openAddChildModal, setOpenAddChildModal] = useState(false);
    const [openEditChildModal, setOpenEditChildModal] = useState(false);
    const [Children, setChildren] = useState([])
    const [date, setDate] = useState('')
    const [startTime, setStartTime] = useState('')
    const [startTimeSlot, setStartTimeSlot] = useState('')
    const [endTimeSlot, setEndTimeSlot] = useState('')
    const [homeVisitCategoryName, setHomeVisitCategoryName] = useState('')
    const [address, setAddress] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [city, setCity] = useState('')
    const [Phone, setPhone] = useState('')
    const [Sprinkle, setSprinkle] = useState('')
    const [PresentLocation, setPresentLocation] = useState('')
    const [Parking, setParking] = useState('')
    const [Comments, setComments] = useState('')
    const [Adults, setAdults] = useState('')
    const [planned, setPlanned] = useState(false)
    const [message, setMessage] = useState('')
    const [childrenSet, setChildrenSet] = useState(false)
    const [adultsSet, setAdultsSet] = useState(false)
    const [rowData, setRowData] = useState([])
    const [rowDataPresent, setRowDataPresent] = useState([])

    // Get ID from URL
    const params = useParams();
    const goToLogout = () => {
        let path = `/logout`;
        navigate(path);
    }
    useEffect(() => {

        if (token) {
            //User is logged in.
            //Get all variables.
            fetch(Url + 'user/home-visit-details/' + params.id, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': "Bearer " + token,
                },
            }).then((res) => {
                if (res.status === 401) {
                    //get 401 unauthorized, go to log out page.
                    goToLogout()
                }
                if (res.status === 200) {

                } else {
                    //Loading failed
                    setMessage("Het laden van de gegevens is mislukt.")
                }
                return res.json()
            })
                .then((data) => {
                    if (data.Children) {
                        setChildren(data.Children)
                    }
                    if (data.Adults) {
                        setAdults(data.Adults)
                    }
                    if (data.Phone) {
                        setPhone(data.Phone)
                    }
                    if (data.Date) {
                        setDate(data.Date)
                    }
                    if (data.Planned) {
                        setPlanned(data.Planned)
                    }
                    if (data.StartTime) {
                        setStartTime(data.StartTime)
                    }
                    if (data.StartTimeSlot) {
                        setStartTimeSlot(data.StartTimeSlot)
                    }
                    if (data.EndTimeSlot) {
                        setEndTimeSlot(data.EndTimeSlot)
                    }
                    if (data.HomeVisitCategoryName) {
                        setHomeVisitCategoryName(data.HomeVisitCategoryName)
                    }
                    if (data.Address) {
                        setAddress(data.Address)
                    }
                    if (data.ZipCode) {
                        setZipCode(data.ZipCode)
                    }
                    if (data.City) {
                        setCity(data.City)
                    }
                    if (data.Sprinkle) {
                        setSprinkle(data.Sprinkle)
                    }
                    if (data.Comments) {
                        setComments(data.Comments)
                    }
                    if (data.PresentLocation) {
                        setPresentLocation(data.PresentLocation)
                    }
                    if (data.Parking) {
                        setParking(data.Parking)
                    }
                })
        }
    }, [Url, params.id, token, openAddChildModal, openEditChildModal])

    const getColumnsChildren = () => [
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Leeftijd",
            accessor: "Age",
        },
        {
            id: "edit-button",
            Cell: <MdEdit/>
        }
    ];
    const getColumnsAdults = () => [
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Achternaam",
            accessor: "LastName",
        },
        {
            id: "edit-button",
            Cell: <MdEdit/>
        }
    ];

    let navigate = useNavigate();


    function handleRowData(rowDataInput) {
        setRowData(rowDataInput)
        if (rowData) {
            setOpenEditChildModal(true)
        }
    }

    function SaveHomeVisitDetails() {
        setMessage("Bezig met opslaan...")
        fetch(Url + 'user/home-visit', {
            method: 'PATCH',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                ID: Number(params.id),
                Phone: Phone,
                Parking: Parking,
                PresentLocation: PresentLocation,
                Sprinkle: Sprinkle,
                Comments: Comments,
            }),
        }).then((res) => {
            if (res.status === 200) {
                setStep(2)
                setMessage("")
            } else {
                setMessage("Het opslaan is mislukt, controleer of alle velden zijn ingevuld.")
            }
        })
    }

    function validatedStep0() {
        //Validate
        if (Phone === "") {
            setMessage("Vul een geldig telefoonnummer in.")
        } else {
            setMessage("")
            setStep(1)
        }

    }

    function validatedStep1() {
        if (Sprinkle === '' || Sprinkle === 0 || Sprinkle === '0') {
            setMessage("Kan je nog aangeven of we mogen strooien?")
        } else {
            SaveHomeVisitDetails()
        }
    }

    function validatedStep2() {
        setStep(3)
    }

    function validatedStep3() {
        setStep(4)
    }

    useEffect(() => {
        if (Children.length < 1) {
            setChildrenSet(false)
        } else {
            setChildrenSet(true)
        }
    }, [Children, openAddChildModal, openEditChildModal]);
    useEffect(() => {
        if (Adults.length < 1) {
            setAdultsSet(false)
        } else {
            setAdultsSet(true)
        }
    }, [Adults, openAddChildModal, openEditChildModal]);


    if (step === 0) {
        return (
            <FormLayout>
                <div>
                    <H1Header headerText="Welkom"></H1Header>
                    <div className="my-4">
                        <div className="my-4">
                            We komen bij jullie langs voor Pakjesavond, gezellig! Om het feest tot een geweldig succes
                            te maken hebben wij jouw hulp nodig. Wij kunnen niet bij elke schoorsteen luisteren maar wij
                            willen wel graag een aantal dingen weten.
                            Op de volgende pagina's stellen wij een aantal vragen over het bezoek en de aanwezigen. Deze
                            informatie gebruiken de entertainers om invulling te geven aan de show. Ook komt dit terecht
                            in het Grote Boek.
                        </div>
                        <div className="my-4">
                            In elk optreden gebruiken we de informatie die in het Grote Boek staat. Vaak zijn er heel
                            veel dingen te zeggen over kinderen, daarom kan het zijn dat we niet alle informatie die je
                            ons geeft gaan gebruiken.
                        </div>
                        <div className="my-4">
                            Alle informatie die u hier invult wordt alleen gedeeld met de entertainers die bij jullie
                            over de vloer komen. Daarna worden de gegevens uit onze database verwijderd.
                        </div>
                        <div className="my-4">
                            De onderstaande gegevens zijn voor ons een dubbelcheck. Controleer deze gegevens en neem
                            contact op via info@ridderendeprins.nl als deze niet blijken te kloppen. Het mobiele
                            telefoonnummer kun je wel direct in dit formulier aanpassen.
                        </div>
                    </div>
                    <H1Header headerText="Algemene gegevens"></H1Header>
                    <div className="">
                        Datum: {date}
                    </div>
                    <div className="">
                        Verwachte starttijd: {planned ? startTime : startTimeSlot + " - " + endTimeSlot}
                    </div>
                    <div className="">
                        Bezoek door: {homeVisitCategoryName}
                    </div>
                    <H1Header headerText="Bezoekadres"></H1Header>
                    <div className="">
                        Adres: {address}
                    </div>
                    <div className="">
                        Postcode: {zipCode}
                    </div>
                    <div className="">
                        Plaats: {city}
                    </div>
                    <H1Header headerText="Contact"></H1Header>
                    <div className="mb-4">
                        Mocht er iets zijn, dan bellen wij op onderstaand telefoonnummer, zorg dat dit telefoonnummer
                        bereikbaar is rond het tijdstip van het bezoek.
                    </div>
                    <TextInput
                        labelName="Telefoonnummer"
                        inputName="phone"
                        onChange={(e) => setPhone(e.target.value)}
                        value={Phone}
                        placeholder="Telefoonnummer"
                        type="text"
                    />
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <div className="flex justify-end">
                        <DefaultButton content="Volgende" onClick={validatedStep0}></DefaultButton>
                    </div>
                </div>
            </FormLayout>
        )
    }
    if (step === 1) {
        return (
            <FormLayout>
                <div>
                    <H1Header headerText="Wat kun je van ons verwachten?"></H1Header>
                    <H2Header headerText="Invulling van ons bezoek"></H2Header>
                    <div className="mb-4">
                        Het bezoek wordt altijd geïmproviseerd aan de hand van de door jou aangeleverde informatie. We
                        hebben in allerlei onderwerpen verhalen, acts en liedjes paraat die we kunnen inzetten op het
                        moment dat de kinderen juist dat onderwerp aansnijden.
                    </div>
                    <H2Header headerText="Cadeaus"></H2Header>
                    <div className="mb-4">
                        Wij sluiten het bezoek af met het binnenbrengen van de cadeaus, indien die aanwezig zijn, en
                        leiden hiermee het begin van jullie feest in. Zo hoeven de kinderen hun aandacht niet te
                        verdelen. Zijn er cadeaus aanwezig en zo ja, is al bekend waar deze verstopt liggen? Piet zal
                        altijd bij een van de volwassenen controleren of de locatie klopt. Mocht de locatie wijzigen na
                        het invullen van het Grote Boek, dan is dat geen enkel probeem.
                    </div>
                    <TextArea labelName="Geef hier de locatie van de cadeau's op indien deze al bekend is"
                              inputName="PresentLocation"
                              onChange={(e) => setPresentLocation(e.target.value)}
                              value={PresentLocation}
                              placeholder=""
                              rows={2}
                    />
                    <H2Header headerText="Strooien"></H2Header>
                    <div className="mb-4">
                        Bij vertrek en wellicht tijdens het bezoek willen wij graag strooien. Mag dat?
                    </div>
                    <SelectInput labelName=""
                                 inputName="Sprinkle"
                                 onChange={(e) => setSprinkle(e.target.value)}
                                 value={Sprinkle}
                                 errorMessage="Selecteer een van de opties uit de lijst"
                                 options={
                                     [
                                         {
                                             ID: "Ja zeker!",
                                             Name: "Ja zeker!"
                                         },
                                         {
                                             ID: "Ja, maar met mate",
                                             Name: "Ja, maar met mate"
                                         },
                                         {
                                             ID: "Nee, liever niet",
                                             Name: "Nee, liever niet"
                                         },
                                     ]
                                 }
                    />
                    <H1Header headerText="Wat verwachten wij van jou?"></H1Header>
                    <H2Header headerText="Parkeren"></H2Header>
                    <div className="mb-4">
                        Wij parkeren graag bij jullie in de straat en het liefst voor de deur. Ook krijgen we graag meer
                        informatie over de parkeersituatie die voor ons relevant kunnen zijn, denk hierbij bijvoorbeeld
                        aan betaald parkeren, een gereserveerde parkeerplaats, een lastig te vinden parkeerplaats, etc.
                    </div>
                    <TextArea labelName="Welke informatie is relevant over de parkeersituatie bij jullie thuis?"
                              inputName="parking"
                              onChange={(e) => setParking(e.target.value)}
                              value={Parking}
                              placeholder=""
                              rows={2}
                    />
                    <H2Header headerText="Binnenkomst"></H2Header>
                    <div className="mb-4">
                        Wij komen graag binnen via de voordeur. Daarom vragen wij de gordijnen aan die kant dicht te
                        doen zodat wij ongezien tot voor de deur kunnen komen. Mocht dit lastig zijn kan je dit aangeven
                        in de bijzonderheden.
                    </div>
                    <H2Header headerText="Ons toneel"></H2Header>
                    <div className="mb-4">
                        Door ons bij jullie thuis uit te nodigen wordt jullie huiskamer ons toneel. Daarom willen wij je
                        vragen voorafgaand en tijdens het optreden te denken aan:


                    </div>
                    <ul>
                        <li> &#x2022; Graag een hoge stoel (zonder wieltjes) klaarzetten om makkelijk kinderen op schoot
                            te kunnen nemen.
                        </li>
                        <li> &#x2022; Niet roken tijdens en direct voorafgaand aan ons bezoek.</li>
                        <li> &#x2022; Als u verwacht dat uw hond ons erg spannend vindt, dan vragen om de hond tijdens
                            het bezoek in een aparte ruimte onder te brengen.
                        </li>
                    </ul>
                    <H2Header headerText="Bijzonderheden"></H2Header>
                    <TextArea labelName="Geeft hier alle bijzonderheden op."
                              inputName="comments"
                              onChange={(e) => setComments(e.target.value)}
                              value={Comments}
                              placeholder=""
                              rows={2}
                    />
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <div className="flex justify-center">
                        <div className="m-4">
                            <DefaultButton content="Vorige" onClick={() => setStep(0)}></DefaultButton>
                        </div>
                        <div className="m-4">
                            <DefaultButton content="Volgende" onClick={validatedStep1}></DefaultButton>
                        </div>
                    </div>
                </div>
            </FormLayout>
        )
    }
    if (step === 2) {
        return (
            <FormLayout>
                <div>
                    <H1Header headerText="Overzicht kinderen"></H1Header>
                    <div className="my-4">Voeg hier alle kinderen toe. Klaar met toevoegen? Klik dan
                        op volgende.
                    </div>

                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <div className="flex justify-center">
                        <DefaultButton content="Voeg een kind toe" onClick={() => setOpenAddChildModal(true)}
                                       disabled={false}></DefaultButton>
                        <NewChildModal openModal={openAddChildModal} setOpenModal={() => setOpenAddChildModal()}
                                       children={Children} setChildren={() => setChildren} welcomePage={false}
                                       homeVisitID={params.id}/>
                        <EditChildModal openModal={openEditChildModal} setOpenModal={() => setOpenEditChildModal()}
                                        children={Children} setChildren={setChildren} rowData={rowData}
                                        welcomePage={false}/>

                    </div>
                    <div className="flex justify-center">
                        {childrenSet &&
                            <SmallTable tableData={Children} onRowClick={handleRowData}
                                        getColumns={getColumnsChildren()}/>}
                    </div>
                    <div className="flex justify-center">
                        <div className="m-4">
                            <DefaultButton content="Vorige" onClick={() => setStep(1)}></DefaultButton>
                        </div>
                        <div className="m-4">
                            <DefaultButton content="Volgende" onClick={validatedStep2}></DefaultButton>
                        </div>

                    </div>
                </div>
            </FormLayout>
        )
    }
    if (step === 3) {
        return (
            <FormLayout>
                <div>
                    <H1Header headerText="Overzicht volwassenen"></H1Header>
                    <div className="my-4">
                        We willen graag van iedereen die aanwezig is weten wie ze zijn, dat geldt dus ook voor de
                        volwassenen. Vul hier informatie in over de aanwezige volwassenen. Graag weten we ook hun
                        relatie tot de aanwezige kinderen.
                    </div>
                    <div className="my-4">Voeg hier alle volwassenen toe. Klaar met toevoegen? Klik dan
                        op Opslaan.
                    </div>

                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <div className="flex justify-center">
                        <DefaultButton content="Volwassene toevoegen"
                                       onClick={() => setOpenAddChildModal(true)}
                                       disabled={false}>
                        </DefaultButton>

                        <NewAdultModal openModal={openAddChildModal} setOpenModal={() => setOpenAddChildModal()}
                                       homeVisitID={params.id}/>
                        <EditAdultModal openModal={openEditChildModal} setOpenModal={() => setOpenEditChildModal()}
                                        children={Children} setChildren={setChildren} rowData={rowData}
                                        welcomePage={false}/>

                    </div>

                    <div className="flex justify-center">
                        {adultsSet &&
                            <SmallTable tableData={Adults} onRowClick={handleRowData}
                                        getColumns={getColumnsAdults()}/>}
                    </div>
                    <div className="flex justify-center">
                        <div className="m-4">
                            <DefaultButton content="Vorige" onClick={() => setStep(2)}></DefaultButton>
                        </div>
                        <div className="m-4">
                            <DefaultButton content="Opslaan" onClick={validatedStep3}></DefaultButton>
                        </div>

                    </div>
                </div>
            </FormLayout>
        )
    }
    if (step === 4) {
        return (
            <FormLayout>
                <div>
                    <H1Header headerText="Opslaan is gelukt"></H1Header>
                    <div className="my-4">Bedankt voor het invullen van het Grote Boek! Je bent helemaal klaar. Wij
                        wensen je alvast een fantastisch feest!
                    </div>
                    <div className="my-4">
                        Wil je alvast van onze muziek genieten? Dat kan via onze
                        <a className="text-blue-700 hover:text-blue-950" target="_blank" rel="noreferrer"
                           href="https://open.spotify.com/artist/6mQqL5ouDAa4dyeZaKIzC8?si=nizcMLEYRSal2Qlw0UWtMw"> Spotify. </a>
                        Tot ziens!
                    </div>

                    <div className="my-4">
                        Klik op de overzicht hieronder om terug te gaan naar het overzicht met alle bezoeken.
                    </div>
                    <div className="mt-4">
                        <DefaultButton content="Overzicht"
                                       onClick={() => navigate("/home/visits-overview/", {replace: false})}></DefaultButton>
                    </div>
                </div>
            </FormLayout>
        )
    }
}

export default HomeVisitDetailsPage