function H1Header (props: {
    headerText: string | undefined;
}){
    return (
        <div className="py-5">
            <h1 className="text-4xl font-serif font-bold text-gray-900">
                {props.headerText}
            </h1>
        </div>
    );
}

export default H1Header;