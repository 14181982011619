import {Table} from "../../tables/BasicTable";
import React, {useContext, useEffect, useState} from "react";
import {useAuth} from "../../../providers/authProvider";
import BoxLayout from "../../../layouts/BoxLayout";
import DefaultButton from "../../buttons/DefaultButton";
import {ApiContext} from "../../contexts/ApiContext";
import {useNavigate, useParams} from "react-router-dom";
import H2Header from "../../headers/h2";
import DefaultLayoutWithoutSideBar from "../../../layouts/DefaultLayoutWithoutSideBar";


function ContactPersonVisitDetailPage() {
    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const navigate = useNavigate();
    const [companyVisitDetails, setCompanyVisitDetails] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataAvailable, setDataAvailable] = useState(false)

    // Get ID from URL
    const params = useParams();
    const getColumnsChildern = () => [
        {
            Header: "ID",
            accessor: "ID",
        },
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Achternaam",
            accessor: "LastName",
        },
        {
            Header: "Leeftijd",
            accessor: "Age",
        },
        {
            Header: "Email Ourder/verzorger",
            accessor: "Email",
        },
        {
            Header: "Naam Ouder/verzorger",
            accessor: "ParentName",
        },

    ];

    const handleDownload = () => {
        fetch(Url + "user/company-visit-export/" + params.id, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download =  companyVisitDetails.Company + ".xlsx" || "downloaded-file";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    useEffect(() => {
        if (token) {
            try{
                setLoading(true)
                fetch(Url + 'user/company-visit-details/' + params.id, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': "Bearer " + token,
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setDataAvailable(true)
                            return res.json()
                        }else{
                            setDataAvailable(false)
                            return [{
                                "message": "Geen informatie voor dit bedrijfsbezoek beschikbaar."
                            }]
                        }
                    })
                    .then((data) => {
                        setCompanyVisitDetails(data)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }catch (error){
                setDataAvailable(false)
                return [{
                    "message": "Laden niet gelukt."
                }]
            }
        }
    }, [token, Url, params.id ]);
    let dataTable
    if (companyVisitDetails.Children){
        dataTable =  (<Table tableData={companyVisitDetails.Children} setRowdata={undefined} onRowClick={() => console.log("click")} getColumns={getColumnsChildern()}></Table>)
    }else {
        dataTable = <div>Er zijn nog geen kinderen ingeschreven voor dit bezoek</div>
    }
    let ownChildrenButton
    if (companyVisitDetails.OwnChildren){
        ownChildrenButton =  (<div>
            Je hebt zelf kinderen aangemeld voor het feest. Klik op de knop bewerken om deze inschrijvingen te wijzigen.
            <DefaultButton content="Bewerk" onClick={() => navigate("/user/company-visits-overview", {replace: false})}></DefaultButton>
        </div>)

    } else{
        ownChildrenButton = (<div>
                Wil je zelf kinderen aanmelden? Klik dan op de op inschrijven hieronder.
            <DefaultButton content="Inschrijven" onClick={() => navigate("/company/welcome?signup_code=" + companyVisitDetails.SignUpCode, { replace: false })}></DefaultButton>
            </div>
        )
    }

    let presentServiceInfo
    if (companyVisitDetails.PresentServiceActive){
        presentServiceInfo = (
            <div>
                <div className="text-xl">Cadeauservice actief: JA</div>
                <div className="text-xl">Cadeauservice categorie: {companyVisitDetails.PresentCategory}</div>
            </div>)
    }else{
        presentServiceInfo = (
            <div>
                <div className="text-xl">Cadeauservice actief: Nee</div>
            </div>)
    }
    let dataViewer

    if (dataAvailable) {
        let SignUpTarget
        SignUpTarget = "/company/welcome?signup_code="+companyVisitDetails.SignUpCode
        dataViewer = (
            <BoxLayout>
                <div className="mb-4 mt-4">
                    <DefaultButton content="Terug" onClick={() => navigate("/company/visits-overview/", { replace: false })}></DefaultButton>
                </div>
                <H2Header headerText={companyVisitDetails.Company}/>
                <H2Header headerText="Algemeen"/>
                <div className="text-xl">Datum: {companyVisitDetails.Date}</div>
                <div className="text-xl">Starttijd: {companyVisitDetails.StartTime}</div>
                <div className="text-xl">Eindtijd: {companyVisitDetails.EndTime}</div>
                <div className="text-xl">Aantal ingeschreven kinderen: {companyVisitDetails.NumberOfChildren}</div>
                <div className="text-xl">Aantal ingeschreven volwassen: {companyVisitDetails.NumberOfAdults}</div>
                {presentServiceInfo}
                <div className="text-xl">Aanmeldcode: {companyVisitDetails.SignUpCode}</div>
                <div className="text-xl">Aanmeldlink: <a target="_blank" href={SignUpTarget}
                                                         rel="noreferrer">{window.location.origin + "/company/welcome?signup_code=" + companyVisitDetails.SignUpCode}</a>
                </div>
                <div className="mb-4 mt-4">
                    <DefaultButton content="Download" onClick={handleDownload}></DefaultButton>
                </div>

                <H2Header headerText="Locatie"/>
                <div className="text-xl">Adres: {companyVisitDetails.Address}</div>
                <div className="text-xl">Postcode: {companyVisitDetails.Zipcode}</div>
                <div className="text-xl">Plaats: {companyVisitDetails.City}</div>

                <H2Header headerText="Ingeschreven kinderen"/>
                {ownChildrenButton}
                {dataTable}
            </BoxLayout>
        )
    } else {
        dataViewer = (
            <BoxLayout>
                <div>Geen data voor dit bezoek in de database</div>
            </BoxLayout>
        )
    }
    return (
        <DefaultLayoutWithoutSideBar>
            {loading ? (
                <BoxLayout><div>Loading...</div></BoxLayout>

            ) : (
                <div className="flex flex-col overflow-auto py-4 sm:py-0">
                    {dataViewer}
                </div>
            )}
        </DefaultLayoutWithoutSideBar>
    )
}
export default ContactPersonVisitDetailPage