
import React, {useContext, useEffect, useState} from "react";
import {useAuth} from "../../../providers/authProvider";
import BoxLayout from "../../../layouts/BoxLayout";
import DefaultButton from "../../buttons/DefaultButton";
import {ApiContext} from "../../contexts/ApiContext";
import {useNavigate} from "react-router-dom";
import H2Header from "../../headers/h2";
import DefaultLayoutWithoutSideBar from "../../../layouts/DefaultLayoutWithoutSideBar";
import H1Header from "../../headers/h1";


function HomeVisitsOverviewPage() {

    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const [homeVisits, setHomeVisits] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataAvailable, setDataAvailable] = useState(false)
    const navigate = useNavigate();
    function handleHomeVisitDetails (id){
        navigate("/home/visit-details/" + id, { replace: false })
    }
    useEffect(() => {
        if (token) {
            try{
                setLoading(true)
                fetch(Url + 'user/home-visits', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': "Bearer " + token,
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setDataAvailable(true)
                            return res.json()
                        }else{
                            setDataAvailable(false)
                            return [{
                                "message": "Geen huisbezoeken gevonden"
                            }]
                        }
                    })
                    .then((data) => {
                        setHomeVisits(data)
                    })
                    .finally(() => {
                        setLoading(false)

                    })
            }catch (error){
                setDataAvailable(false)
                return [{
                    "message": "Laden niet gelukt."
                }]
            }
        }
    }, [token, Url]);
    let dataTable
    if (dataAvailable){
        dataTable = (
            homeVisits.map((homeVisit) =>
                <div className="hover:bg-blue-400 hover:bg-opacity-30" onClick={() => handleHomeVisitDetails(homeVisit.ID)} key={homeVisit.ID}>
                    <hr/>
                    <div className="grid grid-cols-3">
                        <div className="col-span-3">
                            <H2Header headerText= {homeVisit.HomeVisitCategoryName + " | " +  homeVisit.Date}/>
                            <p>Locatie: {homeVisit.Address + ", " + homeVisit.ZipCode + ", " + homeVisit.City}</p>
                            <div className="mb-4 mt-4">
                                <DefaultButton content="Grote boek invullen" onClick={undefined}></DefaultButton>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }else{
        dataTable = (
            <div>Er zijn nog geen huisbezoeken gevonden in ons systeem. Heb je deze net aangevraagd, dan kan het even duren voordat deze hier zichtbaar is.
                <div>
                <DefaultButton content="Aanvragen"
                               onClick={() => window.open('https://ridderendeprins.nl/contact', '_blank')}></DefaultButton>
            </div>
    </div>
        )
    }
    return (
        <DefaultLayoutWithoutSideBar>
            {loading ? (
                <BoxLayout><div>Loading...</div></BoxLayout>

            ) : (
                <div className="flex flex-col overflow-auto py-4 sm:py-0">
                    <BoxLayout>
                        <H1Header headerText="Overzicht van jouw huisbezoeken"/>
                        {dataTable}
                    </BoxLayout>
                </div>
            )}
        </DefaultLayoutWithoutSideBar>
    )
}
export default HomeVisitsOverviewPage