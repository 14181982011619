import {useCallback} from "react";
import ButtonTable from "../buttons/ButtonTable";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";

function PaginationNav (props: {
    gotoPage: any,
    pageCount: any,
    pageIndex: any,
}) {
    const renderPageLinks = useCallback(() => {
        if (props.pageCount === 0) return null;
        const visiblePageButtonCount = 3;
        let numberOfButtons =
            props.pageCount < visiblePageButtonCount ? props.pageCount : visiblePageButtonCount;
        const pageIndices = [props.pageIndex];
        numberOfButtons--;
        [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
            const pageNumberBefore = pageIndices[0] - 1;
            const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;
            if (
                pageNumberBefore >= 0 &&
                (itemIndex < numberOfButtons / 2 || pageNumberAfter > props.pageCount - 1)
            ) {
                pageIndices.unshift(pageNumberBefore);
            } else {
                pageIndices.push(pageNumberAfter);
            }
        });
        return pageIndices.map((pageIndexToMap) => (
            <li key={pageIndexToMap}>
                <ButtonTable
                    content={pageIndexToMap + 1}
                    onClick={() => props.gotoPage(pageIndexToMap)}
                    active={props.pageIndex === pageIndexToMap}
                />
            </li>
        ));
    }, [props]);
    return (
        <ul className="flex gap-2">
            <li>
                <ButtonTable
                    content={
                        <div className="flex ml-1">
                            <FaChevronLeft size="0.6rem" />
                            <FaChevronLeft size="0.6rem" className="-translate-x-1/2" />
                        </div>
                    }
                    onClick={() => props.gotoPage(0)}
                    active={false}/>
            </li>
            {renderPageLinks()}
            <li>
                <ButtonTable
                    content={
                        <div className="flex ml-1">
                            <FaChevronRight size="0.6rem" />
                            <FaChevronRight size="0.6rem" className="-translate-x-1/2" />
                        </div>
                    }
                    onClick={() => props.gotoPage(props.pageCount - 1)}
                    active = {false}/>
            </li>
        </ul>
    );
}

export default PaginationNav;

