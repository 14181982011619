function H2Header (props: {
    headerText: string | undefined;
}){
    return (
        <div className="py-5">
            <h2 className="text-3xl font-serif font-bold text-gray-900">
                {props.headerText}
            </h2>
        </div>
    );
}

export default H2Header;