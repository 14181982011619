import {
    useTable,
    useGlobalFilter,
    useSortBy,
    usePagination,
} from "react-table";
import { useMemo } from "react";
import {TableComponent} from "./TableComponent";

function SmallTable(
    props: {
        tableData: any,
        getColumns: any,
        onRowClick: any,
    }
) {
    const data = props.tableData;
    const columns = useMemo(() => props.getColumns, [props.getColumns]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page: rows,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 100 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    return (
        <div className="flex flex-col gap-4">
            <TableComponent
                getTableProps={getTableProps}
                headerGroups={headerGroups}
                getTableBodyProps={getTableBodyProps}
                rows={rows}
                prepareRow={prepareRow}
                onRowClick={props.onRowClick}
            />
        </div>
    );
}

export { SmallTable };
