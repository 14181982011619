
"use client";

import {Modal } from "flowbite-react";
import TextInput from "../input/TextInput";
import React, {useContext, useState} from "react";
import DefaultButton from "../buttons/DefaultButton";
import {useAuth} from "../../providers/authProvider";
import {ApiContext} from "../contexts/ApiContext";
import {ValidationContext} from "../contexts/ValidationContext";
import CheckBoxInput from "../input/CheckBoxInput";
import SelectInput from "../input/SelectInput";
import TextArea from "../input/TextArea";

function NewHomeVisitModal(
    props: {
        openModal: boolean,
        setOpenModal: boolean,
        HomeVisitsCategories: any,

    }
) {
    const { token } = useAuth();
    const { Url } = useContext(ApiContext)
    const [Email, setEmail] = useState('')
    const [VisitDate, setVisitDate] = useState('')
    const [StartTime, setStartTime] = useState('')
    const [StartTimeSlot, setStartTimeSlot] = useState('')
    const [EndTimeSlot, setEndTimeSlot] = useState('')
    const [Planned, setPlanned] = useState(false)
    const [Address, setAddress] = useState('')
    const [ZipCode, setZipCode] = useState('')
    const [City, setCity] = useState('')
    const [Locked, setLocked] = useState(false)
    const [Payed, setPayed] = useState(false)
    const [PresentLocation, setPresentLocation] = useState('')
    const [Team, setTeam] = useState('')
    const [message, setMessage] = useState('')
    const [HomeVisitCategoryID, setHomeVisitCategoryID] = useState(1)
    const [Comments, setComments] = useState('')
    const [Sprinkle, setSprinkle	] = useState('')


    const {nameRegex, nameErrorMessage} = useContext(ValidationContext)
    function addPresentCategory(event) {
        event.preventDefault()
        fetch(Url + 'admin/home-visit', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                Date: VisitDate,
                StartTime: StartTime,
                StartTimeSlot: StartTimeSlot,
                EndTimeSlot: EndTimeSlot,
                Planned: Planned,
                Locked: Locked,
                Address: Address,
                City: City,
                ZipCode: ZipCode,
                Email: Email,
                HomeVisitCategoryID: Number(HomeVisitCategoryID),
                Payed: Payed,
                PresentLocation: PresentLocation,
                Team: Team.toUpperCase(),
                Sprinkle: Sprinkle,
                Comments: Comments,
            }),
        })
            .then((res) => {
                if (res.status === 200) {
                    setVisitDate('')
                    setStartTime('')
                    setStartTimeSlot('')
                    setEndTimeSlot('')
                    setPlanned(false)
                    setAddress('')
                    setZipCode('')
                    setCity('')
                    setLocked(false)
                    setEmail('')
                    setHomeVisitCategoryID(1)
                    setPayed(false)
                    setTeam('')
                }
                return res.json()
            })
            .then((data) => {
                setMessage(data.message)
            })
    }

    return (
        <Modal  show={props.openModal} position="center" onClose={() => props.setOpenModal(false)} >
            <Modal.Header><p className="m-4">Nieuw huisbezoek toevoegen</p></Modal.Header>
            <Modal.Body>
                <div className="space-y-6 m-4">
                    <p className="text-base leading-relaxed text-gray-500">
                        Geef de gegevens van het huisbezoek op en klik op toevoegen. Het mailadres moet van een
                        bestaande
                        gebruiker zijn. Bestaat deze gebruiker nog niet? Voeg dan eerst een nieuwe gebruiker toe.
                    </p>

                    <TextInput
                        labelName="Email adres van de klant*"
                        inputName="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={Email}
                        placeholder="Email"
                        errorMessage="Vul hier een geldig email in"
                        pattern={undefined}
                        type="email"
                    />
                    <p>Selecteer het type optreden</p>
                    <SelectInput labelName="Type optreden*"
                                 inputName="HomeVisitCategoryID"
                                 onChange={(e) => setHomeVisitCategoryID(e.target.value)}
                                 value={HomeVisitCategoryID}
                                 errorMessage="Selecteer een van de opties uit de lijst"
                                 options={props.HomeVisitsCategories}
                    />
                    <TextInput
                        labelName="Bezoekdatum*"
                        inputName="visitDate"
                        value={VisitDate}
                        placeholder="05/12/2024"
                        errorMessage="Vul hier geldig datum in"
                        pattern={undefined}
                        type="date"
                        onChange={(e) => setVisitDate(e.target.value)}
                    />
                    <TextInput
                        labelName="Definitieve aankomsttijd"
                        inputName="startTime"
                        onChange={(e) => setStartTime(e.target.value)}
                        value={StartTime}
                        placeholder="13:00"
                        errorMessage="Vul hier een geldig tijdstip in"
                        pattern={undefined}
                        type="time"
                    />
                    <TextInput
                        labelName="Begin aankomsttijdsslot"
                        inputName="startTimeSlot"
                        onChange={(e) => setStartTimeSlot(e.target.value)}
                        value={StartTimeSlot}
                        placeholder="13:00"
                        errorMessage="Vul hier een geldig tijdstip in"
                        pattern={undefined}
                        type="time"
                    />
                    <TextInput
                        labelName="Einde aankomsttijdsslot"
                        inputName="EndTimeSlot"
                        onChange={(e) => setEndTimeSlot(e.target.value)}
                        value={EndTimeSlot}
                        placeholder="13:00"
                        errorMessage="Vul hier een geldig tijdstip in"
                        pattern={undefined}
                        type="time"
                    />
                    <p>
                        Op het moment dat een huisbezoek de status "Bevestigd" heeft ziet de klant het tijdstip welke is
                        ingevulgd bij geplande starttijd. Op het moment dat het huisbezoek nog niet bevestigd is, ziet
                        de klant een tijdblok waartussen de starttijd zich kan bevinden.
                    </p>
                    <CheckBoxInput labelName="Tijdstip bevestigd" inputName="planned" value="yes" checked={Planned}
                                   setChecked={setPlanned}/>
                    <TextInput
                        labelName="Adres*"
                        inputName="adres"
                        onChange={(e) => setAddress(e.target.value)}
                        value={Address}
                        placeholder="Straat + Huisnummer"
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text"
                    />
                    <TextInput
                        labelName="Postcode*"
                        inputName="postcode"
                        onChange={(e) => setZipCode(e.target.value)}
                        value={ZipCode}
                        placeholder="1234AA"
                        errorMessage="Vul hier de postcode zonder spatie in"
                        pattern="[0-9a-zA-Z ]{6,6}"
                        type="text"
                    />
                    <TextInput
                        labelName="Plaats*"
                        inputName="city"
                        onChange={(e) => setCity(e.target.value)}
                        value={City}
                        placeholder="Plaats"
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text"
                    />
                    <SelectInput labelName="Strooien"
                                 inputName="Sprinkle"
                                 onChange={(e) => setSprinkle(e.target.value)}
                                 value={Sprinkle}
                                 errorMessage="Selecteer een van de opties uit de lijst"
                                 options={
                                     [
                                         {
                                             ID: "Ja zeker!",
                                             Name: "Ja zeker!"
                                         },
                                         {
                                             ID: "Ja, maar met mate",
                                             Name: "Ja, maar met mate"
                                         },
                                         {
                                             ID: "Nee, liever niet",
                                             Name: "Nee, liever niet"
                                         },
                                     ]
                                 }
                    />
                    <TextArea
                        labelName="Locatie van de cadeau's."
                        inputName="presentLocation"
                        onChange={(e) => setPresentLocation(e.target.value)}
                        value={PresentLocation}
                        placeholder="In de schuur"
                        type="text"
                        rows={3}/>
                    <TextArea
                        labelName="Bijzonderheden"
                        inputName="comments"
                        onChange={(e) => setComments(e.target.value)}
                        value={Comments}
                        placeholder=""
                        type="text"
                        rows={3}/>
                    <TextInput
                        labelName="Team"
                        inputName="team"
                        onChange={(e) => setTeam(e.target.value)}
                        value={Team}
                        placeholder="A"
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text"
                    />
                    <p>
                        Op het moment dat een huisbezoek de status "Locked" heeft kan er niks meer door de klant
                        gewijzigd
                        worden. Dit doe je alleen vlak voor het moment dat je het grote boek gaat exporten.
                    </p>
                    <CheckBoxInput labelName="Locked" inputName="locked" value="yes" checked={Locked}
                                   setChecked={setLocked}/>
                    <p>
                        Hier kan je aangegeven of de factuur van de klant betaalt is.
                    </p>
                    <CheckBoxInput labelName="Factuur betaalt" inputName="payed" value="yes" checked={Payed}
                                   setChecked={setPayed}/>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-10 grid grid-cols-1 gap-0 md:grid-cols-2">
                <div className="flex justify-center items-center col-span-1 m-4">
                        <DefaultButton onClick={addPresentCategory} content="Toevoegen"/>
                    </div>
                    <div className="flex justify-center items-center col-span-1 m-4">
                    <DefaultButton onClick={() => props.setOpenModal(false)} content="Sluiten"/>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
);
}
export default NewHomeVisitModal
