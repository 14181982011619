function PasswordInput (props: {
    labelName: string | undefined;
    inputName: string | undefined;
    pattern?: string | undefined,
    errorMessage?: string | undefined,
    onChange: any
}){
    return (
        <div>
            <label>
                <p className="text-gray-500">
                    {props.labelName}
                </p>

            </label>
            <input
                className="border-gray-400 w-full focus:border-blue-900 focus:outline-none border-2 p-2 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] font-normal transition-colors rounded-lg [&:not(:placeholder-shown):not(:focus):invalid~span]:block invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-400"
                name={props.inputName || ''}
                onChange={props.onChange}
                value={props.value}
                pattern={props.pattern}
                placeholder="Wachtwoord"
                type="password"
            />
            <span className="mt-1 hidden text-sm text-red-400">
                   {props.errorMessage}
            </span>
        </div>

    );
}

export default PasswordInput;