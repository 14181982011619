
"use client";

import {Modal } from "flowbite-react";
import TextInput from "../input/TextInput";
import {useContext, useState} from "react";
import DefaultButton from "../buttons/DefaultButton";
import NumberInput from "../input/NumberInput";
import {useAuth} from "../../providers/authProvider";
import {ApiContext} from "../contexts/ApiContext";
import {ValidationContext} from "../contexts/ValidationContext";

function NewHomeVisitCategoryModal(
    props: {
        openModal: boolean,
        setOpenModal: boolean,
    }
) {
    const { token } = useAuth();
    const { Url } = useContext(ApiContext)
    const [name, setName] = useState('')
    const [salesPrice, setSalesPrice] = useState('')
    const [message, setMessage] = useState('')
    const {nameRegex, nameErrorMessage} = useContext(ValidationContext)
    function addPresentCategory(event) {
        event.preventDefault()
        fetch(Url + 'admin/home-visit-categories', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                Name: name,
                SalesPrice: salesPrice
            }),
        })
            .then((res) => {
                if (res.status === 200) {
                    setName('')
                    setSalesPrice('')

                }
                return res.json()
            })
            .then((data) => {
                setMessage(data.message)
            })
    }

    return (
        <Modal  show={props.openModal} position="center" onClose={() => props.setOpenModal(false)} >
            <Modal.Header><p className="m-4">Nieuw huisbezoek categorie toevoegen</p></Modal.Header>
            <Modal.Body>
                <div className="space-y-6 m-4">
                    <p className="text-base leading-relaxed text-gray-500">
                        Geeft naam van de categorie en de verkoopprijs op en klik op toevoegen.
                    </p>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <TextInput labelName="Naam"
                               inputName="name"
                               onChange={(e) => setName(e.target.value)}
                               value={name}
                               placeholder="Naam"
                               errorMessage={nameErrorMessage}
                               pattern={nameRegex}
                               type="text"
                    />
                    <NumberInput labelName="Verkoopprijs"
                               inputName="salesPrice"
                               onChange={(e) => setSalesPrice(e.target.value)}
                               value={salesPrice}
                               placeholder="Prijs in euros"
                               errorMessage="Vul hier het bedrag in euro's in. Gebruik alleen getallen."
                               type="number"
                               min={0}
                               max={undefined}
                    />

                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-10 grid grid-cols-1 gap-0 md:grid-cols-2">
                    <div className="flex justify-center items-center col-span-1 m-4">
                        <DefaultButton onClick={addPresentCategory} content="Toevoegen"/>
                    </div>
                    <div className="flex justify-center items-center col-span-1 m-4">
                        <DefaultButton onClick={() => props.setOpenModal(false)} content="Sluiten"/>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
);
}
export default NewHomeVisitCategoryModal
