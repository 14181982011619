
"use client";

import {Modal } from "flowbite-react";
import DefaultButton from "../buttons/DefaultButton";
import H2Header from "../headers/h2";
import React, {useContext, useState} from "react";
import {ApiContext} from "../contexts/ApiContext";
import {useAuth} from "../../providers/authProvider";


function PresentSelectionModal(
    props: {
        openModal: boolean,
        setOpenModal: boolean,
        presents: any,
        children?: any,
        setChildren?: any,
        welcomePage: boolean,
        rowData: any,
        admin?: boolean
    }) {
    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const [message, setMessage] = useState('')
    let patchUrl
    if (props.admin){
        patchUrl = Url + 'admin/child-present'
    }else{
        patchUrl = Url + 'user/child-present'
    }
    function handlePresentSelection(PresentID, PresentName){
        if (props.welcomePage) {
            const newChildren = props.children.map((c) => {
                if (c.ID === props.rowData.ID) {
                    let child
                    child = c
                    child.PresentID = PresentID
                    child.PresentName = PresentName

                    return child;
                } else {
                    // The rest haven't changed
                    return c;
                }
            })
            props.setChildren(newChildren)
        }
        else{
            setMessage("Bezig met opslaan...")
            fetch(patchUrl, {
                method: 'PATCH',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': "Bearer " + token,
                },
                body: JSON.stringify({
                    ID: props.rowData.ChildID,
                    PresentID: PresentID
                }),
            }).then((res) => {
                if (res.status === 200) {
                    //Saving successfully.
                    setMessage("")
                    props.setOpenModal(false)
                } else {
                    //Saving failed
                    setMessage("Het opslaan is mislukt, neem contact op.")
                }
            })
        }
        props.setOpenModal(false)
    }
    const presents = props.presents.map((present) =>
        <div className="hover:bg-blue-400 hover:bg-opacity-30" onClick={() => handlePresentSelection(present.ID,present.Name)} key={present.ID}>
            <hr/>
            <div className="grid grid-cols-3">
                <div className="col-span-2">
                    <H2Header headerText={present.Name}/>
                    <p>{present.Description}</p>
                    <DefaultButton content="Selecteer dit cadeau" onClick={() => handlePresentSelection(present.ID,present.Name)}></DefaultButton>
                </div>
                <div className="col-span-1">
                    <img src={present.ImageURL} alt={present.Name} width="200" height="200"/>
                </div>
            </div>
        </div>
    )
    return (
        <Modal show={props.openModal} position="center" onClose={() => props.setOpenModal(false)} >
            <Modal.Header><p className="m-4">Cadeau voor {props.rowData.FirstName}</p></Modal.Header>
            <Modal.Body>
                <div className="space-y-6 m-4">
                    <p className="text-base leading-relaxed text-gray-500">
                        Hieronder kun je selecteren welk cadeautje je aan {props.rowData.FirstName} wil laten geven.
                        Selecteer per kind 1 cadeau.
                    </p>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    {presents}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div className="mb-10">
                    <DefaultButton onClick={() => props.setOpenModal(false)} content="Annuleren" />
                </div>

            </Modal.Footer>
        </Modal>
    );
}
export default PresentSelectionModal
