import {
    useTable,
    useGlobalFilter,
    useSortBy,
    usePagination,
} from "react-table";
import { useMemo } from "react";


import GlobalSearchFilter from "./TableSearch";
import {TableComponent} from "./TableComponent";
import PaginationNav from "./PaginationNav";


function Table(
    props: {
        tableData: any,
        getColumns: any,
        onRowClick: any,
    }
) {
    const data = props.tableData;
    const columns = useMemo(() => props.getColumns, [props.getColumns]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page: rows,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 25 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col sm:flex-row justify-between gap-2">
                <GlobalSearchFilter
                    className="sm:w-64"
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </div>
            <TableComponent
                getTableProps={getTableProps}
                headerGroups={headerGroups}
                getTableBodyProps={getTableBodyProps}
                rows={rows}
                prepareRow={prepareRow}
                onRowClick={props.onRowClick}
            />
            <div className="flex justify-center">
                <PaginationNav
                    gotoPage={gotoPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                />
            </div>
        </div>
    );
}

export { Table };
