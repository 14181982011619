
"use client";

import {Modal} from "flowbite-react";
import TextInput from "../input/TextInput";
import React, {useContext, useState} from "react";
import DefaultButton from "../buttons/DefaultButton";
import TextArea from "../input/TextArea";
import {ValidationContext} from "../contexts/ValidationContext";
import {ApiContext} from "../contexts/ApiContext";
import {useAuth} from "../../providers/authProvider";

function NewAdultModal(
    props: {
        openModal: boolean,
        setOpenModal: boolean,
        homeVisitID: number,
    }
) {
    const {nameRegex, nameErrorMessage} = useContext(ValidationContext)
    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const [FirstName, setFirstName] = useState('')
    const [LastName, setLastName] = useState('')
    const [Info, setInfo] = useState('')

    const [message, setMessage] = useState('')

    function addAdult() {
        if (FirstName === '' || LastName === '' || Info === '' ){
            setMessage('Nog niet alle  velden zijn ingevuld.')
            return
        }
        addAdultVisitorPage()
    }

    function addAdultVisitorPage(){
        setMessage("Bezig met opslaan...")
        fetch(Url + 'user/adults', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                FirstName : FirstName,
                LastName: LastName,
                Info: Info,
                HomeVisitID: Number(props.homeVisitID),
            }),
        }).then((res) => {
            if (res.status === 200) {
                //Saving successfully.
                setMessage("")
                //clear state data
                setFirstName('')
                setLastName('')
                setInfo('')
                props.setOpenModal(false)
            } else {
                //Saving failed
                setMessage("Het opslaan is mislukt, neem contact op.")
            }
        })
    }
    return (
        <Modal  show={props.openModal} position="center" onClose={() => props.setOpenModal(false)} >
            <Modal.Header><p className="m-4">Nieuwe volwassene toevoegen</p></Modal.Header>
            <Modal.Body>
                <div className="space-y-6 m-4">
                    <p className="text-base leading-relaxed text-gray-500">
                        Geeft de gegevens van de volwassene persoon op en klik op toevoegen. Alle velden zijn verplicht.
                    </p>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <TextInput labelName="Voornaam"
                               inputName="firstName"
                               onChange={(e) => setFirstName(e.target.value)}
                               value={FirstName}
                               placeholder="Voornaam"
                               errorMessage={nameErrorMessage}
                               pattern={nameRegex}
                               type="text"
                    />
                    <TextInput labelName="Achternaam"
                               inputName="lastName"
                               onChange={(e) => setLastName(e.target.value)}
                               value={LastName}
                               placeholder="Achternaam"
                               errorMessage={nameErrorMessage}
                               pattern={nameRegex}
                               type="text"
                    />

                    <TextArea labelName="Info"
                              inputName="info"
                              onChange={(e) => setInfo(e.target.value)}
                              value={Info}
                              placeholder=""
                              rows={4}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-10 grid grid-cols-1 gap-0 md:grid-cols-2">
                <div className=" m-4 flex justify-center">
                        <DefaultButton onClick={addAdult} content="Toevoegen" />
                    </div>
                    <div className="m-4 flex justify-center">
                        <DefaultButton onClick={() => props.setOpenModal(false)} content="Annuleren" />
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
    );
}
export default NewAdultModal
