
"use client";

import {Modal } from "flowbite-react";
import TextInput from "../input/TextInput";
import {useContext, useEffect, useState} from "react";
import {useAuth} from "../../providers/authProvider";
import {ApiContext} from "../contexts/ApiContext";
import {ValidationContext} from "../contexts/ValidationContext";
import DefaultButton from "../buttons/DefaultButton";

function EditCompanyModal(
    props: {
        openModal: any,
        setOpenModal: any,
        rowData: any
    }
) {
    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const [ID,setID] = useState('')
    const [Name, setName] = useState('')
    const [Address, setAddress] = useState('')
    const [City, setCity] = useState('')
    const [Zipcode, setZipcode] = useState('')
    const [message, setMessage] = useState('')
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const {nameRegex, nameErrorMessage} = useContext(ValidationContext)


    function editCompany(event) {
        event.preventDefault()
        fetch(Url + 'admin/companies', {
            method: 'PATCH',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                ID: ID,
                Name: Name,
                Address: Address,
                City: City,
                Zipcode: Zipcode,
            }),
        })
            .then((res) => {
            return res.json()
        })
            .then((data) => {
                setMessage(data.message)
            })
    }

    useEffect(() => {
        setName(props.rowData.Name)
        setAddress(props.rowData.Address)
        setCity(props.rowData.City)
        setZipcode(props.rowData.Zipcode)
        setID(props.rowData.ID)

    }, [props.rowData]);

    function handleClose(){
        setMessage('')
        props.setOpenModal(false)
    }
    function deleteCompany(event){
        event.preventDefault()
        setDeleteConfirm(false)
        fetch(Url + 'admin/companies', {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                ID: ID,
            }),
        })
            .finally(
                props.setOpenModal(false),
            )
    }
    return (
        <Modal  show={props.openModal} position="center" onClose={() => handleClose()} >
            <Modal.Header><p className="m-4">Bewerk Bedrijf (ID:{ID}) - {Name} </p></Modal.Header>
            <Modal.Body>
                <div className="space-y-6 m-4">
                    <p className="text-base leading-relaxed text-gray-500">
                        Bewerk de gegevens en klik op wijzigen
                    </p>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <TextInput
                        labelName="Naam"
                        inputName="Name"
                        value={Name}
                        placeholder="Naam"
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextInput
                        labelName="Adres"
                        inputName="Address"
                        onChange={(e) => setAddress(e.target.value)}
                        value={Address}
                        placeholder="Paashaasplein 1"
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text"
                    />
                    <TextInput
                        labelName="Postcode"
                        inputName="postcode"
                        onChange={(e) => setZipcode(e.target.value)}
                        value={Zipcode}
                        placeholder="1234AA"
                        errorMessage="Vul hier de postcode zonder spatie in"
                        pattern="[0-9a-zA-Z ]{6,6}"
                        type="text"
                    />
                    <TextInput
                        labelName="Plaats"
                        inputName="city"
                        onChange={(e) => setCity(e.target.value)}
                        value={City}
                        placeholder="Plaats"
                        errorMessage={nameErrorMessage}
                        pattern={nameRegex}
                        type="text"
                    />

                    {deleteConfirm &&

                        <div className="">
                            Weet je zeker dat je bedrijf {Name} wilt verwijderen?
                        </div>
                    }
                    {deleteConfirm &&
                        <div className="grid grid-cols-1 gap-0 md:grid-cols-2">
                            <div className="flex justify-center">
                                <DefaultButton onClick={deleteCompany} content="JA" />
                            </div>
                            <div className="flex justify-center">
                                <DefaultButton onClick={() =>(setDeleteConfirm(false))} content="NEE" />
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-10 grid grid-cols-1 gap-0 md:grid-cols-3">
                    <div className="m-4 flex justify-center">
                        <DefaultButton onClick={editCompany} content="Wijzigen" />
                    </div>
                    <div className="m-4 flex justify-center">
                        <DefaultButton onClick={() => setDeleteConfirm(true)} content="Verwijderen" />
                    </div>
                    <div className="m-4 flex justify-center">
                        <DefaultButton onClick={handleClose} content="Sluiten" />
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
    );
}
export default EditCompanyModal
