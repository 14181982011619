

function DlElement (props: {
    color: string | undefined;
    dt: string | undefined;
    dd: string | undefined;
}){
    return (
        <div className={`bg-${props.color} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}>
            <dt className="text-sm font-medium text-gray-500">
                {props.dt}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {props.dd}
            </dd>
        </div>
    );
}

export default DlElement;