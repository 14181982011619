
import React, {useContext, useEffect, useState} from "react";
import {useAuth} from "../../../providers/authProvider";
import BoxLayout from "../../../layouts/BoxLayout";
import DefaultButton from "../../buttons/DefaultButton";
import {ApiContext} from "../../contexts/ApiContext";
import {useNavigate} from "react-router-dom";
import H2Header from "../../headers/h2";
import DefaultLayoutWithoutSideBar from "../../../layouts/DefaultLayoutWithoutSideBar";
import H1Header from "../../headers/h1";


function ContactPersonVisitsOverviewPage() {

    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const [companyVisits, setCompanyVisits] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataAvailable, setDataAvailable] = useState(false)
    const navigate = useNavigate();
    function handleCompanyVisitDetails (id){
        navigate("/company/visit-details/" + id, { replace: false })
    }
    useEffect(() => {
        if (token) {
            try{
                setLoading(true)
                fetch(Url + 'user/company-visits', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': "Bearer " + token,
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setDataAvailable(true)
                            return res.json()
                        }else{
                            setDataAvailable(false)
                            return [{
                                "message": "Geen bedrijfszoeken"
                            }]
                        }
                    })
                    .then((data) => {
                        setCompanyVisits(data)
                    })
                    .finally(() => {
                        setLoading(false)

                    })
            }catch (error){
                setDataAvailable(false)
                return [{
                    "message": "Laden niet gelukt."
                }]
            }
        }
    }, [token, Url]);
    let dataTable
    if (dataAvailable){
        dataTable = (
            companyVisits.map((companyVisit) =>
                <div className="hover:bg-blue-400 hover:bg-opacity-30" onClick={() => handleCompanyVisitDetails(companyVisit.ID)} key={companyVisit.ID}>
                    <hr/>
                    <div className="grid grid-cols-3">
                        <div className="col-span-3">
                            <H2Header headerText={companyVisit.Date + " | " + companyVisit.StartTime + "-" + companyVisit.EndTime}/>
                            <p>Locatie: {companyVisit.Address + ", " + companyVisit.Zipcode + ", " + companyVisit.City}</p>
                            <div className="mb-4 mt-4">
                                <DefaultButton content="Bekijk dit bezoek" onClick={undefined}></DefaultButton>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }else{
        dataTable = (
            <div>Geen bezoeken in de database of je hebt geen rechten om deze informatie te bekijken.</div>
        )
    }
    return (
        <DefaultLayoutWithoutSideBar>
            {loading ? (
                <BoxLayout>
                    <div>Loading...</div>
                </BoxLayout>

            ) : (
                <div className="flex flex-col overflow-auto py-4 sm:py-0">
                    <BoxLayout>
                        <H1Header headerText="Overzicht van de bezoeken"/>
                        <H2Header headerText="Jouw bedrijfsbezoeken"/>
                        {dataTable}
                        <hr/>
                        <div className="flex flex-col overflow-auto py-4 sm:py-0 my-4">
                            <H2Header headerText="Jouw huisbezoeken"/>
                            <div>
                                Sint en Piet kunnen ook bij jou thuis komen. Wil je een huisbezoek aanvragen, klik dan op aanvragen. Wil je jouw huisbezoeken bekijken, klik dan op bekijken.
                            </div>
                            <div className="mt-4">
                                <DefaultButton content="Aanvragen"
                                               onClick={() => window.open('https://ridderendeprins.nl/contact', '_blank')}></DefaultButton>
                            </div>
                            <div className="mt-4">
                                <DefaultButton content="Bekijken"
                                               onClick={() => navigate("/home/visits-overview/", {replace: false})}></DefaultButton>
                            </div>
                        </div>
                    </BoxLayout>
                </div>
            )}

        </DefaultLayoutWithoutSideBar>
    )
}

export default ContactPersonVisitsOverviewPage