
"use client";

import {Modal } from "flowbite-react";
import TextInput from "../input/TextInput";
import React, {useContext, useEffect, useState} from "react";
import DefaultButton from "../buttons/DefaultButton";
import NumberInput from "../input/NumberInput";
import SelectInput from "../input/SelectInput";
import TextArea from "../input/TextArea";
import {ValidationContext} from "../contexts/ValidationContext";
import {useAuth} from "../../providers/authProvider";
import {ApiContext} from "../contexts/ApiContext";

function NewChildModal(
    props: {
        openModal: boolean,
        setOpenModal: boolean,
        setChildren?: any,
        children?: any,
        welcomePage: boolean,
        signUpCode?: string,
        admin?: boolean,
        rowData: any
    }
) {
    const { token } = useAuth();
    const {Url } = useContext(ApiContext)
    const {nameRegex, nameErrorMessage} = useContext(ValidationContext)
    const [FirstName, setFirstName] = useState('')
    const [LastName, setLastName] = useState('')
    const [Age, setAge] = useState('')
    const [Siblings, setSiblings] = useState('')
    const [Parents, setParents] = useState('')
    const [SintSit, setSintSit] = useState('')
    const [Hobbies, setHobbies] = useState('')
    const [message, setMessage] = useState('')
    const [School, setSchool] = useState('')
    const [ChildID, setChildID] = useState('')
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [admin, setAdmin] = useState(false)

    let patchUrl
    if (admin){
        patchUrl = Url + 'admin/children'
    }else{
        patchUrl = Url + 'user/children'
    }

    useEffect(() => {
        setFirstName(props.rowData.FirstName)
        setLastName(props.rowData.LastName)
        setAge(props.rowData.Age)
        setSiblings(props.rowData.Siblings)
        setParents(props.rowData.Parents)
        setSintSit(props.rowData.SintSit)
        setHobbies(props.rowData.Hobbies)
        setSchool(props.rowData.School)
        setChildID(props.rowData.ID)
        if (props.admin){
            setAdmin(true)
        }

    }, [props.admin, props.rowData]);

    function handleClose(){
        setMessage('')
        props.setOpenModal(false)
    }

    function editChild(){

        if (FirstName === '' || LastName === '' || Age === '' || Parents === '' ||  Hobbies === ''){
            setMessage('Nog niet alle verplichte velden zijn ingevuld.')
            return
        }
        if (SintSit === '' || SintSit === '0'){
            setMessage('Selecteer een optie bij "Wil dit kind op schoot bij Sinterklaas of Piet?"')
            return
        }
        if (props.welcomePage){
            editChildWelcome()
        }else{
            editChildVisitorPage()
        }

    }

    function editChildWelcome(){
        let child = {
            ID: ChildID,
            FirstName: FirstName,
            LastName: LastName,
            Age: Number(Age),
            Siblings: Siblings,
            Parents: Parents,
            SintSit: SintSit,
            School: School,
            Hobbies: Hobbies
        }
        let childrenInModal = props.children
        //first remove the child object from the array
        childrenInModal = childrenInModal.filter(children => children.ID !== ChildID)
        //Add the edit child.
        childrenInModal.push(child)
        props.setChildren(childrenInModal)
        //clear state data
        clearData()
        props.setOpenModal(false)
    }
    function editChildVisitorPage(){
        setMessage("Bezig met opslaan...")


        fetch(patchUrl, {
            method: 'PATCH',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                ID: ChildID,
                FirstName : FirstName,
                LastName: LastName,
                Age: Number(Age),
                Parents: Parents,
                Hobbies: Hobbies,
                Siblings: Siblings,
                SintSit: SintSit,
                School: School,
                SignUpCode: props.signUpCode,

            }),
        }).then((res) => {
            if (res.status === 200) {
                //Saving successfully.
                setMessage("")
                //clear state data
                clearData()
                //close modal
                props.setOpenModal(false)
            } else {
                //Saving failed
                setMessage("Het opslaan is mislukt, neem contact op.")
            }
        })
    }
    function removeChild(){
        if (props.welcomePage){
            let children = props.children
            props.setChildren(children.filter(children => children.ID !== ChildID))
            props.setOpenModal(false)
            setDeleteConfirm(false)
        }else{
            setDeleteConfirm(false)
            fetch(patchUrl, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': "Bearer " + token,
                },
                body: JSON.stringify({
                    ID: ChildID,
                }),
            }).then(res => {
                if(res.status === 200 ){
                    props.setOpenModal(false)
                    setMessage("")
                }
                else{
                    setMessage("Verwijderen mislukt")
                }
            })
        }
        //clear state data
        clearData()
    }
    function clearData(){
        setFirstName('')
        setLastName('')
        setAge('')
        setSiblings('')
        setParents('')
        setSintSit('')
        setHobbies('')
    }

    return (
        <Modal  show={props.openModal} position="center" onClose={() => props.setOpenModal(false)} >
            <Modal.Header><p className="m-4">Bewerk {FirstName} {LastName} </p></Modal.Header>
            <Modal.Body>
                <div className="space-y-6 m-4">
                    <p className="text-base leading-relaxed text-gray-500">
                        Bewerk de gegevens van het kind op en klik op wijzigen.
                    </p>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <TextInput labelName="Voornaam*"
                               inputName="firstName"
                               onChange={(e) => setFirstName(e.target.value)}
                               value={FirstName}
                               placeholder="Voornaam"
                               errorMessage={nameErrorMessage}
                               pattern={nameRegex}
                               type="text"
                    />
                    <TextInput labelName="Achternaam*"
                               inputName="lastName"
                               onChange={(e) => setLastName(e.target.value)}
                               value={LastName}
                               placeholder="Achternaam"
                               errorMessage={nameErrorMessage}
                               pattern={nameRegex}
                               type="text"
                    />
                    <NumberInput labelName="Leeftijd*"
                                 inputName="age"
                                 onChange={(e) => setAge(e.target.value)}
                                 value={Age}
                                 placeholder="Leeftijd"
                                 errorMessage="Vul hier de leeftijd van het kind in. Gebruik alleen getallen. De maximale leeftijd is 17 jaar."
                                 type="number"
                                 max={17}
                                 min={0}
                    />
                    <TextInput labelName="Naam van broer(s) en/of zus(sen)"
                               inputName="siblings"
                               onChange={(e) => setSiblings(e.target.value)}
                               value={Siblings}
                               placeholder="Naam(en)"
                               errorMessage={nameErrorMessage}
                               pattern={nameRegex}
                               type="text"
                    />
                    <TextInput labelName="Naam van ouders en/of verzorgers*"
                               inputName="partens"
                               onChange={(e) => setParents(e.target.value)}
                               value={Parents}
                               placeholder="Naam(en)"
                               errorMessage={nameErrorMessage}
                               pattern={nameRegex}
                               type="text"
                    />
                    <SelectInput labelName="Wil dit kind op schoot bij Sinterklaas of Piet?*"
                                 inputName="sintSit"
                                 onChange={(e) => setSintSit(e.target.value)}
                                 value={SintSit}
                                 errorMessage="Selecteer een van de opties uit de lijst"
                                 options={
                                     [
                                         {
                                             ID: "Ja leuk",
                                             Name: "Ja leuk"
                                         },
                                         {
                                             ID: "Ja is vorig jaar ook geweest",
                                             Name: "Ja is vorig jaar ook geweest"
                                         },
                                         {
                                             ID: "Waarschijnlijk niet",
                                             Name: "Waarschijnlijk niet"
                                         },
                                         {
                                             ID: "Geen idee",
                                             Name: "Geen idee"
                                         }
                                     ]
                                 }
                    />
                    <TextInput labelName="School"
                               inputName="school"
                               onChange={(e) => setSchool(e.target.value)}
                               value={School}
                               placeholder="Naam van de School"
                               errorMessage={nameErrorMessage}
                               pattern={nameRegex}
                               type="text"
                    />
                    <TextArea labelName="Hobby's en/of sporten*"
                              inputName="hobbies"
                              onChange={(e) => setHobbies(e.target.value)}
                              value={Hobbies}
                              placeholder=""
                              rows={2}
                    />
                </div>
                {deleteConfirm &&

                    <div className="m-4">
                        Weet je zeker dat je {FirstName} {LastName} wilt verwijderen?
                    </div>
                }
                {deleteConfirm &&
                    <div className="mb-10 grid grid-cols-1 gap-0 md:grid-cols-2">
                        <div className="m-4 flex justify-center">
                            <DefaultButton onClick={removeChild} content="JA" />
                        </div>
                        <div className="m-4 flex justify-center">
                            <DefaultButton onClick={() =>(setDeleteConfirm(false))} content="NEE" />
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <div className="grid grid-cols-1 gap-0 md:grid-cols-3">
                    <div className="m-4 flex justify-center">
                        <DefaultButton onClick={editChild} content="Wijzigen"/>
                    </div>
                    <div className="m-4 flex justify-center">
                        <DefaultButton onClick={() => setDeleteConfirm(true)} content="Verwijderen"/>
                    </div>
                    <div className="m-4 flex justify-center">
                        <DefaultButton onClick={handleClose} content="Sluiten"/>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
    );
}

export default NewChildModal
