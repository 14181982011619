function SelectInput (props: {
    labelName: string | undefined,
    inputName: string | undefined,
    value: string | undefined,
    options: any ,
    errorMessage: string | undefined,
    onChange: any,
}){


    return (
        <div className="mb-2">
            <label>
                <p className="text-gray-500">
                    {props.labelName}
                </p>
            </label>
            <select className="border-gray-400 bg-white w-full focus:border-blue-900 focus:outline-none border-2 p-2 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] font-normal transition-colors rounded-lg [&:not(:placeholder-shown):not(:focus):invalid~span]:block invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-400"
                    name={props.inputName || ''}
                    onChange={props.onChange}
                    value={props.value || ''}
            >
                <option key="0" value={0}>Selecteer een optie</option>
                {props.options.map((option) => (
                    <option key={option.ID} value={option.ID}>{option.Name}</option>
                ))}
            </select>
            <span className="mt-1 hidden text-sm text-red-400">
                   {props.errorMessage}
            </span>
        </div>

    );
}

export default SelectInput;