import React, { ReactNode } from 'react';
import BoxLayout from "./BoxLayout";

const FormLayout: React.FC<{ children: ReactNode }> = ({ children }) => {

    return (
        <div className="">
            {/* <!-- ===== Page Wrapper Start ===== --> */}
            <div className="flex h-screen overflow-hidden">
                {/* <!-- ===== Content Area Start ===== --> */}
                <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
                    {/* <!-- ===== Main Content Start ===== --> */}
                    <main>
                            <BoxLayout>{children}</BoxLayout>
                    </main>
                    {/* <!-- ===== Main Content End ===== --> */}
                </div>
                {/* <!-- ===== Content Area End ===== --> */}
            </div>
            {/* <!-- ===== Page Wrapper End ===== --> */}
        </div>
    );
};
export default FormLayout;

