import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/authProvider";
import React, {useContext, useState} from "react";
import {ApiContext} from "../contexts/ApiContext";
import FormLayout from "../../layouts/FormLayout";
import H1Header from "../headers/h1";
import TextInput from "../input/TextInput";
import PasswordInput from "../input/PasswordInput";
import DefaultButton from "../buttons/DefaultButton";


function LoginPage() {
    const { setToken } = useAuth();
    const navigate = useNavigate();
    const {Url } = useContext(ApiContext)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] =useState('')

    function submitUser(event) {
        event.preventDefault()
        if (password === '' || email === ''){
            //fields are empty
        }
        setMessage('Bezig met inloggen...')
        fetch(Url + 'auth/login', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                email: email.toLowerCase(),
                password: password }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message === 'success') {
                    setToken(data.token)
                    setEmail('')
                    setPassword('')
                    setMessage('')
                    if (data.admin){
                        navigate("/admin/users", { replace: true });
                    }else if (data.companyVisitUser) {
                        navigate("/company/visits-overview", {replace: true});
                    }
                    else if (data.companyVisitor){
                        navigate("/user/company-visits-overview", {replace: true});
                    }else{
                        navigate("/home/visits-overview/", { replace: true });
                    }
                } else {
                    setMessage('Inloggen is niet gelukt, controleer je emailadres en je wachtwoord.')
                }
            })
    }
    function goToPasswordReset(){
        navigate("/password-reset", { replace: true })
    }

    return (

        <FormLayout>
            <form onSubmit={submitUser}>
                <H1Header headerText="Inloggen" ></H1Header>
                <p className="text-base leading-relaxed text-red-500">
                    {message}
                </p>
                <TextInput
                    labelName="Emailadres"
                    inputName="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    pattern={undefined}
                    type="email"
                    errorMessage={"Vul een geldig mailadres in"}>
                </TextInput>
                <PasswordInput labelName="Wachtwoord" inputName="Password" onChange={(e) => setPassword(e.target.value)}></PasswordInput>

                <div className=" mt-4 flex justify-end">
                    <DefaultButton content="LOGIN" onClick={submitUser}></DefaultButton>
                </div>
            </form>
            <div className="mt-4 flex justify-end font-semibold text-sm">
                <button
                    className="text-blue-600 hover:text-blue-700 hover:underline hover:underline-offset-4"
                    onClick={goToPasswordReset}
                >
                    Wachtwoord vergeten?
                </button>
            </div>
        </FormLayout>


    );
}

export default LoginPage;
