import React, { useEffect, useRef } from 'react';
import {Link, NavLink} from 'react-router-dom';
import logo from "../../img/redp_logo.png";


interface SidebarProps {
    sidebarOpen: boolean;
    setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
    const trigger = useRef(null);
    const sidebar = useRef(null);


    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }: MouseEvent) => {
            if (!sidebar.current || !trigger.current) return;
            if (
                !sidebarOpen ||
                sidebar.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setSidebarOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: KeyboardEvent) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    return (
        <aside
            ref={sidebar}
            className={`left-0 top-0 z-9999 flex h-screen w-72.5 flex-col overflow-y-hidden bg-white duration-300 ease-linear lg:static lg:translate-x-0 
            ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}
            ${sidebarOpen ? 'flex' : 'absolute'}
            `}
        >
            {/* <!-- SIDEBAR HEADER --> */}
            <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
                <Link className="mt-4 block flex-shrink-0" to="/">
                    <img src={logo} width={100} height={60} alt="Logo"/>
                </Link>
                <button
                    ref={trigger}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    aria-controls="sidebar"
                    aria-expanded={sidebarOpen}
                    className="block lg:hidden"
                >
                </button>
            </div>
            {/* <!-- SIDEBAR HEADER --> */}
            <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
                {/* <!-- Sidebar Menu --> */}
                <nav className="mt-4 py-4 px-4 lg:mt-9 lg:px-6">
                    <div>
                        <ul className="mb-6 flex flex-col gap-1.5">
                            <li>
                                <NavLink
                                    to="/admin/users"
                                    className={`relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:text-green-500`}
                                >
                                    Gebruikers
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/admin/companies"
                                    className={`relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:text-green-500`}
                                >
                                    Bedrijven
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/admin/company-visits"
                                    className={`relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:text-green-500`}
                                >
                                    Bedrijfsbezoeken
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/admin/home-visits"
                                    className={`relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:text-green-500`}
                                >
                                    Huisbezoeken
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/admin/presents"
                                    className={`relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:text-green-500`}
                                >
                                    Cadeaus
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </nav>
                {/* <!-- Sidebar Menu --> */}
            </div>
        </aside>
    );
};

export default Sidebar;